<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark>
        <strong>Asignar administrador</strong>
      </v-toolbar>

      <v-container>
        <v-card outlined flat style="text-align: center; font-size:x-large;" elevation="0" class="mb-2">{{ equipo.nombre }}</v-card>

        <v-card flat class="scroll-card" outlined elevation="0">
          <v-list>
            <v-list-item v-for="(integrante, index) in equipo.integrantes" :key="index" >
            <!-- button -->
            <v-radio-group v-model="adminSelected">
              <v-radio
                :key="index"
                :value="index"
                @click="seleccionarAdmin(index)"
              >
              </v-radio>

            </v-radio-group> 
            <!-- button -->
            <!-- Avatar -->
            <v-list-item-avatar class="avatar-list">
              <v-avatar color="primary" size="30">
                <v-icon color="white" v-if="!integrante.avatar">mdi-account</v-icon>
                  <img
                    v-else
                    :src="`${imageURL}/avatar/${integrante.avatar}`"
                    alt="UserImage"
                  />
              </v-avatar>
            </v-list-item-avatar>
            <!-- Contenido -->
            <v-list-item-content>
                <v-list-item-title>{{ integrante.nombreCompleto }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="adminLocal._id === integrante._id">
              <v-chip 
                outlined
                color="yellow darken-2"
                label
                small
                text-color="yellow darken-2"
              >Administrador
                <v-icon right>
                  mdi-star
                </v-icon>
              </v-chip>
            </v-list-item-action>
              
          </v-list-item>
        </v-list>
      </v-card>

      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="cancelar()"
        >Cancelar
        </v-btn>
        <v-btn
          small
          color="primary"
          :loading="loading"
          :class="{
            'disable-events': !valido,
          }"
          @click="asignarAdmin()"
        >Guardar
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { modificarEquipoActividadService } from './equipos.service';

export default {
  name: "equipoAdmin",
  props: {
    mostrar: { type: Boolean, default: false },
    idCurso: { type: String || null, default: null },
    idActividad: { type: String || null, default: null },
    equipo: { type: Object, default: null },
  },
  computed: {
    ...mapGetters(["imageURL"]),
    valido() {
      if(this.indexAdminLocal == this.adminSelected) return false;
      return true;
    }
  },
  created() {
    this.asignarValores();
  },
  data() {
    return {
      loading: false,
      adminLocal: null,
      indexAdminLocal: null,
      adminSelected: null,
      nuevoAdmin: null,
    }
  },
  methods: {
    asignarValores() {
      this.indexAdminLocal = this.equipo.integrantes.findIndex(integrante => integrante.admin);
      this.adminLocal = this.equipo.integrantes[this.indexAdminLocal];
      this.adminSelected = this.indexAdminLocal;
    },
    seleccionarAdmin(index) {
      this.equipo.integrantes.find(integrante => {
        if(integrante.admin) integrante.admin = false;
      });

      this.equipo.integrantes[index].admin = true;
      this.nuevoAdmin = this.equipo.integrantes[index].usuario;
    },
    cancelar(){
      this.equipo.integrantes.find(integrante => {
        if(integrante.admin) integrante.admin = false;
      });
      this.equipo.integrantes[this.indexAdminLocal].admin = true;
      this.$emit('cancelar');
    },
    async asignarAdmin() {
      try {
        this.loading = true;

        let integrantes = [];
        this.equipo.integrantes.forEach(integrante => { integrantes.push(integrante.usuario)});

        const serverResponse = await modificarEquipoActividadService(
          this.idCurso,
          this.idActividad,
          this.equipo._id,
          this.equipo.nombre,
          integrantes,
          this.nuevoAdmin
        );

        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("adminModificado", serverResponse.equipoModificado);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}

</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>