<template>
  <v-dialog v-model="mostrar" fullscreen>
    <v-card outlined>
      <v-toolbar dense color="primary" dark flat class="text-h6">
        <v-btn icon @click="prev" :disabled="indexUsuario === 0 || indexEquipo === 0">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <strong v-if="mostrarDocumentoLocal">Previsualizar documento</strong>
        <strong v-else-if="!editarCalificacion && !mostrarDocumentoLocal"> Agregar calificación</strong>
        <strong v-else>Modificar calificación</strong>
        <v-spacer></v-spacer>
        <v-btn icon @click="next" :disabled="indexUsuario === alumnos.length-1 || indexEquipo === equipos.length-1">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Vista principal -->
      <template v-if="mostrarDocumentoLocal">
        <v-container class="justify-center text-center" fluid>
          <v-card outlined style="padding-top: 5px; padding-bottom: 10px">
            <v-avatar size="80">
              <v-icon size="100" v-if="this.alumnos.length != 0">
                mdi-account-circle
              </v-icon>
              <v-icon size="90" v-if="this.equipos.length != 0">
                mdi-account-group
              </v-icon>
            </v-avatar>
            <v-col class="justify-center text-center" cols="12">
                <strong>{{ nombreLocal }}</strong>
                <v-list v-if="this.equipos.length != 0" dense>
                  <v-divider></v-divider>
                  <v-list-item 
                    v-for="integrante in equipos[indexEquipo].integrantes" 
                    :key="integrante._id"
                    style="justify-content: center"
                  >
                    <v-icon v-if="integrante.admin" color="#dfec00" small class="mr-2">mdi-star</v-icon>
                    <span>{{integrante.nombreCompleto}}</span>
                  </v-list-item>
                  <v-divider></v-divider>
                </v-list><br v-if="this.equipos.length == 0"/>
            </v-col>

            <!-- Boton para calificar -->

            <v-card-actions class="justify-center text-center" style="padding-top: 1px;">
              <v-btn small @click="$emit('cancelar')" ><v-icon small>mdi-close-circle-outline</v-icon>Cancelar</v-btn>
              <v-btn small @click="mostrarDocumentoLocal = false"><v-icon small>mdi-pencil</v-icon> Calificar</v-btn>
            </v-card-actions>
            
          </v-card>
        </v-container>
      </template>
      <!---->
      
      <!-- Vista principal calificación -->
      <v-container class="justify-center text-center" fluid v-else>
        <v-card outlined style="padding-top: 5px; padding-bottom: 10px">
          <datosCalificacionComponent
            :tipo="'Archivo'"
            :loading="loading"
            :instrumento="instrumento"
            :alumnos="alumnos"
            :equipos="equipos"
            :nombre="nombreLocal"
            :indexEquipo="indexEquipo"
            :documentos="documentosLocal"
            :calificacion="calificacionLocal"
            :observaciones="observacionesLocal"
            :calificaciones="calificaciones"
            :editar="editarCalificacion"
            :bloquearBtn="bloquearBtn"
            @cancelar="$emit('cancelar')"
            @agregarCalificacion="agregarCalificacion"
          />
        </v-card>
      </v-container>
      <!---->

      <!-- Producto -->
      <v-container class="justify-center text-center" fluid>
        <listaArchivosComponent 
          :idCurso="idCurso"
          :idActividad="idActividad"
          :productos="documentosLocal"
          :disponible="disponible"
          @solicitaRevision="solicitaRevision"
        />
      </v-container>
      <!-- Producto -->

      <v-container v-if="!mostrarDocumentoLocal">
        <v-card outlined>
          <listaCotejoActividadViewComponent
            :esCalificacion="esCalificacion"
            :instrumentoActividad="instrumento"
            :selectedCalificacion="indicadores"
            @calificacionActualizada="calificacionActualizada"
          />
        </v-card>
      </v-container> 
  
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import datosCalificacionComponent from "../datosCalificacion.vue";
import listaArchivosComponent from "./listaArchivos.vue";
import listaCotejoActividadViewComponent from "../../../../../instrumentos/actividad/listaCotejoActividad.view.vue";
import {modificarCalificacionArchivoService} from "../../entregas.service";

export default {
  name: "agregarCalificacionArchivoListaCotejo",
  props: {
    mostrar: { type: Boolean, default: false },
    mostrarCalificar: { type: Boolean, default: false },
    mostrarDocumento: { type: Boolean, default: false },
    editarCalificacion: { type: Boolean, default: false },
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    disponible: { type: Object, default: () => {} },
    instrumento: { type: String, default: null },
    alumnos: { type: Array, default: () => [] },
    equipos: { type: Array, default: () => [] },
    idAlumno: { type: String, default: null },
    idEquipo: { type: String, default: null },
  },
  computed: {
    ...mapGetters(["app", "httpURL"]),
    bloquearBtn() {
      let blockBtn = false;
      let calificacionData = null;
      let documentoActual = null;
      if(this.alumnos.length != 0) documentoActual = this.alumnos[this.indexUsuario].documentos[0] || null;
      if(this.equipos.length != 0) documentoActual = this.equipos[this.indexEquipo].documentos[0] || null;
      // Si selecciona la opción 'agregar' y un usuario cuenta con calificación
      if (!this.editarCalificacion) {
        if (documentoActual)
          typeof(documentoActual.revision.calificacion) == 'number' ? calificacionData = documentoActual.revision.calificacion : calificacionData = null;
        if (!documentoActual || typeof(calificacionData) == 'number' && !documentoActual.revision.reenvio || documentoActual.revision.reenvio) blockBtn = true;
      }
      // Si selecciona la opción 'modificar'
      else {
        if (documentoActual)
          typeof(documentoActual.revision.calificacion) == 'number' ? calificacionData = documentoActual.revision.calificacion : calificacionData = null;
        if (!documentoActual || typeof(calificacionData) != 'number') blockBtn = true;
      }
      return blockBtn;
    },
  },
  data: () => ({
    loading: false,
    mostrarCalificarLocal: false,
    mostrarDocumentoLocal: false,
    calificacionLocal: 0,
    calificaciones: [],
    observacionesLocal: null,
    indicadores: [],
    documentosLocal: [],
    reenvioLocal: null,
    // Alumnos
    indexUsuario: null,
    indexEquipo: null,
    idAlumnoLocal: null,
    idEquipoLocal: null,
    nombreLocal: null,
    // Instrumento
    esCalificacion: true,
  }),
  components: {
    listaCotejoActividadViewComponent,
    datosCalificacionComponent,
    listaArchivosComponent
  },
  watch:{
    indexUsuario() {
      this.setDataUsuario();
    },
    indexEquipo() {
      this.setDataEquipo();
    },
  },
  created() {
    if (this.alumnos.length != 0) {
      this.indexUsuario = this.alumnos.findIndex(alumno => alumno._id === this.idAlumno);
    }
    if (this.equipos.length != 0) {
      this.indexEquipo = this.equipos.findIndex(equipo => equipo._id === this.idEquipo);
    }
    this.mostrarCalificarLocal = this.mostrarCalificar;
    this.mostrarDocumentoLocal = this.mostrarDocumento;
  },
  mounted() {
    if (this.editarCalificacion) {
      if(this.alumnos.length != 0) this.documentosLocal = this.alumnos[this.indexUsuario].documentos;
      if(this.equipos.length != 0) this.documentosLocal = this.equipos[this.indexEquipo].documentos;
      this.calificacionLocal = this.documentosLocal[0].revision.calificacion;
      this.observacionesLocal = this.documentosLocal[0].revision.observaciones;
      this.indicadores = this.documentosLocal[0].revision.indicadores;
      this.reenvioLocal = this.documentosLocal[0].revision.reenvio;
    }
  },
  methods: {
    setDataUsuario() {
      this.idAlumnoLocal = this.alumnos[this.indexUsuario]._id;
      this.nombreLocal = this.alumnos[this.indexUsuario].nombreCompleto;
      this.documentosLocal = this.alumnos[this.indexUsuario].documentos;
      if(this.documentosLocal.length != 0) {
        this.calificacionLocal = this.documentosLocal[0].revision.calificacion || 0;
        this.observacionesLocal = this.documentosLocal[0].revision.observaciones;
        this.indicadores = this.documentosLocal[0].revision.indicadores;
        this.reenvioLocal = this.documentosLocal[0].revision.reenvio;
      }
    },
    setDataEquipo() {
      this.idEquipoLocal = this.equipos[this.indexEquipo]._id;
      this.nombreLocal = this.equipos[this.indexEquipo].nombre;
      this.documentosLocal = this.equipos[this.indexEquipo].documentos;
      if(this.documentosLocal.length != 0) {
        this.calificacionLocal = this.documentosLocal[0].revision.calificacion || 0;
        this.observacionesLocal = this.documentosLocal[0].revision.observaciones;
        this.indicadores = this.documentosLocal[0].revision.indicadores;
        this.reenvioLocal = this.documentosLocal[0].revision.reenvio;
      }
    },
    prev(){
      if (this.alumnos.length != 0) this.indexUsuario--;
      if (this.equipos.length != 0) this.indexEquipo--;
      this.indicadores = [];
      this.calificacionLocal = 0;
      this.observacionesLocal = "";
    },
    next(){
      if (this.alumnos.length != 0) this.indexUsuario++;
      if (this.equipos.length != 0) this.indexEquipo++;
      this.indicadores = [];
      this.calificacionLocal = 0;
      this.observacionesLocal = "";
    },
    // CHECAR
    calificacionActualizada(data){
      this.calificacionLocal = data.calificacion;
      this.indicadores = data.indicadores;
    },
    async solicitaRevision (data) {
      try {
        this.reenvioLocal = data;

        let sendData = {
          idDocumento: this.documentosLocal[0]._id,
          reenvio: this.reenvioLocal
        };

        if (this.alumnos) sendData.idAlumno = this.idAlumnoLocal;
        if (this.equipos) sendData.idEquipo = this.idEquipoLocal;

        this.loading = true;
        const serverResponse = await modificarCalificacionArchivoService(this.idCurso, this.idActividad, sendData);
 
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("calificacionModificadaArchivo", serverResponse.calificacion);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async agregarCalificacion(data) {
      try {
        let sendData = {
          idDocumento: this.documentosLocal[0]._id,
          calificacion: data.calificacion,
          observaciones: data.observaciones,
          indicadores: this.indicadores
        };
        
        if (this.alumnos) sendData.idAlumno = this.idAlumnoLocal;
        if (this.equipos) sendData.idEquipo = this.idEquipoLocal;

        if( data.calificacion === 100 && this.reenvioLocal )
          await this.solicitaRevision(false);

        this.loading = true;
        const serverResponse = await modificarCalificacionArchivoService(this.idCurso, this.idActividad, sendData);

        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
           this.$emit("calificacionModificadaArchivo", serverResponse.calificacion);
        } 
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>