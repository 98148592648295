<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar-two-line@5"
      style="margin: 20px"
    ></v-skeleton-loader>

    <template v-else>
      <!-- Calificar general -->
      <v-card outlined elevation="0">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!vistaSeguimiento && permisoEscritura"
            outlined
            small
            color="primary"
            @click="agregarCalificacionGeneral = true"
            :disabled="botonCalificarGeneral"
            >Calificar todos
            <v-icon small class="pl-1">mdi-account-multiple-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <tablaAlumnos
        v-if="tipoEntrega == 'individual'"
        :idCurso="idCurso"
        :idActividad="idActividad"
        :disponible="disponible"
        :tipoActividad="tipoActividad"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :alumnos="alumnos"
        :calificaciones="calificaciones"
        :documentos="documentos"
        :permisoEscritura="permisoEscritura"
        @calificacionAgregada="calificacionAgregada"
        @calificacionModificada="calificacionModificada"
        @calificacionModificadaArchivo="calificacionModificadaArchivo"
      />
      <tablaEquipos
        v-else
        :idCurso="idCurso"
        :idActividad="idActividad"
        :idModulo="idModulo"
        :disponible="disponible"
        :tipoActividad="tipoActividad"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :entregaActividad="entrega"
        :alumnos="alumnos"
        :equipos="equipos"
        :calificaciones="calificaciones"
        :documentos="documentos"
        :permisoEscritura="permisoEscritura"
        @equipoAgregado="equipoAgregado"
        @equipoModificado="equipoModificado"
        @equiposImportados="equiposImportados"
        @equipoActividadEliminado="equipoActividadEliminado"
        @calificacionAgregada="calificacionAgregada"
        @calificacionModificada="calificacionModificada"
        @calificacionModificadaArchivo="calificacionModificadaArchivo"
      />
    </template>

    <!-- Componente para agregar calificación general -->
    <agregarCalificacionGeneralComponent
      v-if="agregarCalificacionGeneral"
      :mostrar="agregarCalificacionGeneral"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      :tipoActividad="tipoActividad"
      :permisoEscritura="permisoEscritura"
      @cancelar="agregarCalificacionGeneral = false"
      @calificacionesAgregadas="calificacionesAgregadas"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { listadoEntregasActividadService } from "./entregas.service";
import { ordenarObjetosAsc } from "../../../../helpers/ordenador";
import tablaAlumnos from "./tablaAlumnos.vue";
import tablaEquipos from "./tablaEquipos.vue";
import agregarCalificacionGeneralComponent from "./calificacionEntregas/calificarGeneral.vue";

export default {
  name: "listadoEntregas",
  props: {
    actualizarTipo: Number,
    actualizarEntregas: Number,
    actualizarActividad: String,
  },
  components: {
    tablaAlumnos,
    tablaEquipos,
    agregarCalificacionGeneralComponent,
  },
  watch: {
    sessionToken() {
      this.cargarListadoEntregas();
    },
    "$route.params.idActividad"(value) {
      this.idActividad = value;
      this.cargarListadoEntregas();
    },
    actualizarTipo() {
      this.cargarListadoEntregas();
    },
    actualizarEntregas() {
      this.cargarListadoEntregas();
    },
    actualizarActividad() {
      this.cargarListadoEntregas();
    },
  },
  computed: {
    ...mapGetters(["sessionToken", "vistaSeguimiento"]),
    tipoEntrega() {
      return this.entrega == 1 ? "individual" : "equipo";
    },
    botonCalificarGeneral() {
      if (this.entrega === 1) if (this.alumnos.length === 0) return true;
      if (this.entrega === 2) if (this.equipos.length === 0) return true;
      if (this.tipoActividad === 2)
        if (this.documentos.length === 0) return true;
      return false;
    },
  },
  mounted() {
    this.idCurso = this.$route.params.idCurso;
    this.idActividad = this.$route.params.idActividad;
    if (this.sessionToken) this.cargarListadoEntregas();
  },
  data() {
    return {
      loading: false,
      idCurso: null,
      idActividad: null,
      idModulo: null,
      tipoActividad: 1,
      instrumento: null,
      tipoInstrumento: null,
      disponible: null,
      entrega: 1,
      alumnos: [],
      equipos: [],
      calificaciones: [],
      documentos: [],
      permisoEscritura: false,
      agregarCalificacionGeneral: false,
    };
  },
  methods: {
    async cargarListadoEntregas() {
      try {
        this.loading = true;
        const serverResponse = await listadoEntregasActividadService(
          this.idCurso,
          this.idActividad
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.idModulo = serverResponse.idModulo;
          this.entrega = serverResponse.entrega;
          this.disponible = serverResponse.disponible;
          this.tipoActividad = serverResponse.tipoActividad;
          this.instrumento = serverResponse.instrumento;
          this.tipoInstrumento = serverResponse.tipoInstrumento;
          this.calificaciones = serverResponse.calificaciones;
          this.permisoEscritura = serverResponse.permisoEscritura;
          this.documentos = serverResponse.documentos;
          if (serverResponse.alumnos.length > 0)
            this.alumnos = ordenarObjetosAsc(serverResponse.alumnos, "nombreCompleto");
          this.equipos = serverResponse.equipos;
        }
      } catch (error) {
        this.extemporaneo = !this.extemporaneo;
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    calificacionAgregada(calificacion) {
      this.calificaciones.push(calificacion);
    },
    calificacionModificada(calificacion) {
      const indexCalificacion = this.calificaciones.findIndex(
        (x) => x._id == calificacion._id
      );
      this.calificaciones[indexCalificacion].calificacion =
        calificacion.calificacion;
      this.calificaciones[indexCalificacion].observaciones =
        calificacion.observaciones;
      this.calificaciones[indexCalificacion].indicadores =
        calificacion.indicadores;
    },
    calificacionModificadaArchivo(calificacion) {
      const indexDocumento = this.documentos.findIndex(
        (x) => x._id == calificacion._id
      );

      this.documentos[indexDocumento].revision.calificacion =
        calificacion.revision.calificacion;
      this.documentos[indexDocumento].revision.observaciones =
        calificacion.revision.observaciones;
      this.documentos[indexDocumento].revision.fecha =
        calificacion.revision.fecha;
      this.documentos[indexDocumento].revision.reenvio =
        calificacion.revision.reenvio;
      this.documentos[indexDocumento].revision.indicadores =
        calificacion.revision.indicadores;
    },
    equipoAgregado(equipo) {
      this.equipos.push(equipo);
      this.$emit("equipoAgregado", equipo);
    },
    equipoModificado(equipo) {
      const index = this.equipos.findIndex((x) => x._id == equipo._id);
      if (index >= 0) {
        this.equipos[index].nombre = equipo.nombre;
        this.equipos[index].integrantes = equipo.integrantes;
      }
    },
    equiposImportados(equipos) {
      this.equipos = equipos;
    },
    equipoActividadEliminado(idEquipo) {
      const index = this.equipos.findIndex((x) => x._id == idEquipo);
      if (index >= 0) this.equipos.splice(index, 1);
      this.$emit("equipoActividadEliminado", idEquipo);
    },
    calificacionesAgregadas(calificaciones) {
      this.agregarCalificacionGeneral = false;
      if (this.tipoActividad === 1) {
        calificaciones.forEach((calificacion) => {
          this.calificaciones.push(calificacion);
        });
      }
      if (this.tipoActividad === 2) {
        calificaciones.forEach((calificacion) => {
          this.documentos.forEach((documento) => {
            if (calificacion._id === documento._id) {
              documento.revision = calificacion.revision;
            }
          });
        });
      }
    },
  },
};
</script>
