<template>
  <div class="text-center">
    <v-menu offset-x right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on">
          <v-icon small>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-if="!cursoCampus" @click="asignarAdmin()">
          <v-list-item-title>Asignar admin</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-account-star</v-icon></v-list-item-icon>
        </v-list-item>
        <v-list-item @click="modificarEquipo()">
          <v-list-item-title>Modificar equipo</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
        </v-list-item>
        <v-list-item v-if="!cursoCampus" @click="eliminarEquipo = true">
          <v-list-item-title>
            <font color="#D32F2F"> Eliminar equipo </font>
          </v-list-item-title>
          <v-list-item-icon
            ><v-icon color="red darken-2" small
              >mdi-trash-can-outline</v-icon
            ></v-list-item-icon
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <eliminarEquipoComponent 
      v-if="eliminarEquipo"
      :mostrar="eliminarEquipo"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :idEquipo="idEquipo"
      @cancelar="eliminarEquipo=false"
      @equipoActividadEliminado="equipoActividadEliminado"
    />
  </div>
</template>

<script>
import eliminarEquipoComponent from "./eliminarEquipo.vue";

export default {
  name: "equipoMenu",
  components: { eliminarEquipoComponent },
  props: {
    idCurso: String,
    idActividad: String,
    idEquipo: String,
    cursoCampus: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: true,
      eliminarEquipo: false,
    };
  },
  methods: {
    asignarAdmin() {
      this.$emit("asignarAdmin", this.idEquipo);
    },
    modificarEquipo() {
      this.$emit("modificarEquipo", this.idEquipo);
    },
    equipoActividadEliminado(idEquipo) {
      this.eliminarEquipo = false;
      this.$emit("equipoActividadEliminado", idEquipo);
    },
  },
};
</script>
