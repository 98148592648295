<template>
  <v-container>
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else rounded="lg">
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-btn icon @click="regresarCurso()"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-spacer></v-spacer> {{ nombreModulo }} <v-spacer></v-spacer>
        <actividadMenuComponent
          v-if="!vistaSeguimiento && permisoEscritura"
          :idActividad="idActividad"
          :idCurso="idCurso"
          :actividad="actividad"
          :idModulo="idModulo"
          :tipoCurso="tipoCurso"
          :disabled="loading"
          @nombreModificado="nombreModificado"
          @eliminarActividad="regresarCurso"
        />
      </v-toolbar>

      <actividadLoadingComponent v-if="loading" />

      <contenidoActividadComponent
        v-else
        :tipoCurso="tipoCurso"
        :idCurso="idCurso"
        :actividad="actividad"
        :equipos="equipos"
        :tipoModulo="tipoModulo"
        :tipoVista="tipoVista"
        :actividadPrevia="actividadPrevia"
        :actividadSiguiente="actividadSiguiente"
        :permisoEscritura="permisoEscritura"
        :cursoArchivado="cursoArchivado"
        @cambioExtemporaneo="cambioExtemporaneo"
        @cambioFormarEquipos="cambioFormarEquipos"
        @criterioModificado="criterioModificado"
        @ponderacionModificada="ponderacionModificada"
        @tipoModificado="tipoModificado"
        @entregaModificada="entregaModificada"
        @instrumentoModificado="instrumentoModificado"
        @formatoArchivoModificado="formatoArchivoModificado"
        @cambioFechaEntrega="cambioFechaEntrega"
        @equipoAgregado="equipoAgregado"
        @equipoActividadEliminado="equipoActividadEliminado"
      />
    </v-card>
  </v-container>
</template>

<script>
import actividadMenuComponent from "./actividadMenu.vue";
import actividadLoadingComponent from "./actividadLoading.vue";
import contenidoActividadComponent from "./contenidoActividad.vue";
import {
  getEquiposActividad,
  obtenerActividadService,
} from "./actividad.service";
import { mapGetters } from "vuex";
import { permisoMenuEscuela } from "../../../helpers/utilerias";

export default {
  name: "actividadView",
  components: {
    actividadMenuComponent,
    actividadLoadingComponent,
    contenidoActividadComponent,
  },
  computed: {
    ...mapGetters(["sessionToken", "menu", "vistaSeguimiento"]),
  },
  watch: {
    sessionToken() {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.cargarActividad();
      }
    },
    "$route.params.idActividad"(value) {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.idActividad = value;
        this.cargarActividad();
      }
    },
  },
  mounted() {
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.idActividad = this.$route.params.idActividad;
    this.tipoModulo = this.$route.params.tipoModulo;
    this.tipoVista = this.$route.params.tipoVista;
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.cargarActividad();
      }
    }
  },
  data() {
    return {
      loading: true,
      idCurso: null,
      idActividad: null,
      tipoModulo: null,
      idModulo: null,
      nombreModulo: "Cargando actividad...",
      tipoCurso: "campus",
      tipoVista: null,
      actividad: {},
      equipos: [],
      actividadSiguiente: null,
      actividadPrevia: null,
      permisoEscritura: false,
      escuelaTienePermiso: false,
      cursoArchivado: false,
    };
  },
  methods: {
    async cargarActividad() {
      try {
        this.loading = true;
        const serverResponse = await obtenerActividadService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.actividad = serverResponse.actividad;
          this.equipos = serverResponse.equipos;
          this.nombreModulo = this.actividad.modulo.nombre;
          this.idModulo = this.actividad.modulo._id;
          this.actividadPrevia = serverResponse.previous;
          this.actividadSiguiente = serverResponse.next;
          this.permisoEscritura = serverResponse.permisoEscritura;
          if (this.tipoCurso === "campus") {
            this.cursoArchivado = serverResponse.cursoArchivado;
            if (serverResponse.seguimiento == false) this.$store.dispatch("setSesionCursoAlumno",serverResponse.sesionAlumno);
            if (serverResponse.seguimiento == true) this.$store.dispatch("setVistaSeguimiento",serverResponse.seguimiento);
            if (!serverResponse.sesionAlumno)
              await this.cargarEquiposActividad();
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    regresarCurso() {
      if (this.tipoVista == "general") {
        this.$router.push(
          `/curso/${this.tipoCurso}/${this.idCurso}#actividades`
        );
      } else {
        this.$router.push(
          `/curso/${this.tipoCurso}/${this.idCurso}/tipo/${this.tipoModulo}/${this.actividad.modulo._id}`
        );
      }
    },
    cambioExtemporaneo(data) {
      this.actividad.entregaExt = data;
    },
    cambioFormarEquipos(data) {
      this.actividad.formarEquipos = data;
    },
    criterioModificado(data) {
      this.actividad.criterio = data.criterio;
      this.actividad.puntos = data.puntosActividad;
      this.actividad.ponderacion = -1;
    },
    ponderacionModificada(data) {
      this.actividad.ponderacion = data.ponderacion;
      this.actividad.puntos = data.puntos;
    },
    tipoModificado(data) {
      this.actividad.tipo = data.tipo;
      this.actividad.formatoArchivo = null;
    },
    entregaModificada(data) {
      this.actividad.entrega = data.entrega;
      if (data.entrega == 1) this.equipos = [];
    },
    instrumentoModificado(data) {
      this.actividad.instrumento = data.instrumento;
      this.actividad.tipoInstrumento = data.tipoInstrumento;
    },
    formatoArchivoModificado(data) {
      this.actividad.formatoArchivo = data.formatoArchivo;
    },
    cambioFechaEntrega(disponible) {
      this.actividad.disponible = disponible;
    },
    equipoAgregado(equipo) {
      this.equipos.push(equipo);
    },
    equipoActividadEliminado(idEquipo) {
      const index = this.equipos.findIndex((x) => x._id == idEquipo);
      if (index >= 0) this.equipos.splice(index, 1);
    },
    nombreModificado(nombre) {
      this.actividad.nombre = nombre;
    },
    async cargarEquiposActividad() {
      try {
        this.loading = true;
        const serverResponse = await getEquiposActividad({
          idCurso: this.idCurso,
          idActividad: this.idActividad,
        });
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.equipos = serverResponse.equipos;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
