<template>
  <v-dialog v-model="mostrar" width="500" :fullscreen="instrumento?true:false" persistent>
    <v-card outlined>
      <v-toolbar dense color="primary" dark flat class="text-h6">
        <v-spacer></v-spacer>
        <strong>Calificación general</strong>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-alert v-if="tipoActividad === 1" color="orange lighten-1" dense dark class="text-center">
          Esta acción asignará nuevas calificaciones a los usuarios registrados.
        </v-alert>
        <v-alert v-if="tipoActividad === 2" color="orange lighten-1" dense dark class="text-center">
          Esta acción modificará las calificaciones de los usuarios que cuenten con un documento registrado.
        </v-alert>
      </v-container>

      <!-- Datos calificación -->
      <v-container class="justify-center text-center" fluid>
        <v-card outlined>
          <datosCalificacionComponent
            :calGeneral='true'
            :loading="loading"
            :instrumento="instrumento"
            :calificacion="calificacionLocal"
            :observaciones="observacionesLocal"
            :calificaciones="calificaciones"
            @cancelar="$emit('cancelar')"
            @agregarCalificacion="agregarCalificacion"
          />
        </v-card>
      </v-container>
      <!-- Datos calificación -->

      <!-- Instrumento -->
      <v-container v-if="instrumento" fluid>
        <v-card outlined>
          <listaCotejoActividadViewComponent
            v-if="instrumento && tipoInstrumento === 'listaCotejo'"
            :esCalificacion="esCalificacion"
            :instrumentoActividad="instrumento"
            :selectedCalificacion="indicadores"
            @calificacionActualizada="calificacionActualizada"
          />

          <rubricaActividadViewComponent
            v-if="instrumento && tipoInstrumento === 'rubrica'" 
            :esCalificacion="esCalificacion"
            :instrumentoActividad="instrumento"
            :selectedCalificacion="indicadores"
            @calificacionActualizada="calificacionActualizada"
          />
        </v-card>
      </v-container>
      <!-- Instrumento -->

    </v-card>
  </v-dialog>
</template>

<script>

import datosCalificacionComponent from "./datosCalificacion.vue";
import listaCotejoActividadViewComponent from "../../../../instrumentos/actividad/listaCotejoActividad.view.vue";
import rubricaActividadViewComponent from "../../../../instrumentos/actividad/rubricaActividad.view.vue";

import { agregarCalificacionGeneralService } from "../entregas.service";

export default {
  name: "agregarCalificacionGeneral",
  props: {
    mostrar: { type: Boolean, default: false },
    idCurso: { type: String, default: null },
    idActividad: {type: String, default: null },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    tipoActividad: { type: Number, default: null },
  },
  components: {
    datosCalificacionComponent,
    listaCotejoActividadViewComponent,
    rubricaActividadViewComponent
  },
  computed: {
    calificaciones() {
      let calificaciones = [];
      if(!this.instrumento) {
        for (let i = 100; i >= 0; i--) calificaciones.push(i);
        return calificaciones;
      }
      else return calificaciones;
    }
  },
  data: () => ({
    loading: false,
    calificacionLocal: null,
    observacionesLocal: null,
    indicadores: [],
    // Instrumento
    esCalificacion: true,
  }),
  created() {
    this.asignarCalificacion();
  },
  methods: {
    asignarCalificacion() {
      if(!this.instrumento) this.calificacionLocal = 100;
      else this.calificacionLocal = 0;
    },
    calificacionActualizada(data){
      this.calificacionLocal = data.calificacion;
      this.indicadores = data.indicadores;
    },
    async agregarCalificacion(data) {
      try {
        let sendData = {
          calificacion: data.calificacion,
          observaciones: data.observaciones,
        }

        if(this.instrumento) sendData.indicadores = this.indicadores;
        
        this.loading = true;
        const serverResponse = await agregarCalificacionGeneralService(
          this.idCurso,
          this.idActividad,
          sendData
        );
        this.loading = false;
        if (!serverResponse.ok) this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("calificacionesAgregadas", serverResponse.calificaciones);

        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}

</script>