import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VMenu,{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":_vm.disabled}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.habilitarEdicion()}}},[_c(VListItemTitle,[_vm._v("Modificar actividad")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c(VListItem,{on:{"click":function($event){_vm.eliminarActividad = true}}},[_c(VListItemTitle,[_c('font',{attrs:{"color":"#D32F2F"}},[_vm._v(" Eliminar actividad ")])],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"red darken-2","small":""}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)],1),(_vm.eliminarActividad)?_c('eliminarActividadComponent',{attrs:{"mostrar":_vm.eliminarActividad,"idCurso":_vm.idCurso,"actividad":_vm.actividad,"idModulo":_vm.idModulo,"tipoCurso":_vm.tipoCurso},on:{"cancelar":function($event){_vm.eliminarActividad = false},"eliminarActividad":_vm.eliminar}}):_vm._e(),(_vm.modificarActividad)?_c('modificarActividadComponent',{attrs:{"mostrar":_vm.modificarActividad,"idCurso":_vm.idCurso,"nombreAnterior":_vm.editarActividad.nombre,"idActividad":_vm.editarActividad._id},on:{"cancelar":function($event){_vm.modificarActividad = false},"nombreModificado":_vm.actividadModificada}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }