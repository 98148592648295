<template>
  <datatable
    :headers="headers"
    :pagination="pagination"
    :datos="alumnosLocal"
    :itemsPerPage="alumnosLocal.length"
    :totalItems="alumnosLocal.length"
    hideSearch
    hidePagination
  >
    <template #item-avatar="{ value }">
      <v-avatar color="primary" size="30">
        <v-icon color="white" v-if="!value">mdi-account</v-icon>
        <img v-else :src="`${imageURL}/avatar/${value}`" alt="UserImage" />
      </v-avatar>
    </template>

    <template #item-calificacion="{ item }">
      <calDirectaItem
        v-if="tipoActividad == 1"
        :idCurso="idCurso"
        :idActividad="idActividad"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :alumnos="alumnosLocal"
        :idAlumno="item._id"
        :calificaciones="item.calificaciones"
        :nombre="item.nombreCompleto"
        :permisoEscritura="permisoEscritura"
        @calificacionAgregada="calificacionAgregada"
        @calificacionModificada="calificacionModificada"
      />

      <calArchivoItem
        v-if="tipoActividad == 2"
        :idCurso="idCurso"
        :idActividad="idActividad"
        :disponible="disponible"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :alumnos="alumnosLocal"
        :idAlumno="item._id"
        :nombre="item.nombreCompleto"
        :alumno="seleccionarAlumno(item._id)"
        :documentos="seleccionarDocumentosAlumno(item._id)"
        :permisoEscritura="permisoEscritura"
        @calificacionModificadaArchivo="calificacionModificadaArchivo"
      />
    </template>

    <template v-if="tipoActividad == 2" #item-documento="{ item }">
      <documentoItem
        :idCurso="idCurso"
        :idActividad="idActividad"
        :disponible="disponible"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :alumnos="alumnosLocal"
        :idAlumno="item._id"
        :nombre="item.nombreCompleto"
        :alumno="seleccionarAlumno(item._id)"
        :documentos="seleccionarDocumentosAlumno(item._id)"
        :permisoEscritura="permisoEscritura"
      />
    </template>
  </datatable>
</template>

<script>
import { mapGetters } from "vuex";
import datatable from "../../../widgets/datatable.vue";
import calDirectaItem from "./calDirectaItem.vue";
import calArchivoItem from "./calArchivoItem.vue";
import documentoItem from "./documentoItem.vue";
import { ordenarObjetosDesc } from "../../../../helpers/ordenador";

export default {
  name: "tablaAlumnos",
  props: {
    idCurso: { type: String || null, default: null },
    idActividad: { type: String || null, default: null },
    disponible: { type: Object, default: () => {} },
    tipoActividad: { type: Number, default: 1 },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    alumnos: { type: Array, default: () => [] },
    calificaciones: { type: Array, default: () => [] },
    documentos: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    datatable,
    calDirectaItem,
    calArchivoItem,
    documentoItem,
  },
  computed: {
    ...mapGetters(["imageURL"]),
    alumnosLocal() {
      const alumnosResponse = [];
      let numero = 1;
      for (const alumno of this.alumnos) {
        let alumnoItem = { numero: numero++, ...alumno };
        let calificaciones = this.calificaciones.filter(
          (x) => x.usuario == alumnoItem._id
        );
        alumnoItem.documentos = this.documentos.filter(
          (x) => x.usuario == alumnoItem._id
        );
        alumnoItem.calificaciones = ordenarObjetosDesc(calificaciones, "fecha");
        alumnosResponse.push(alumnoItem);
      }
      return alumnosResponse;
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "30px",
        },
        {
          text: "",
          value: "avatar",
          sortable: false,
          filterable: false,
          width: "50px",
        },
        {
          text: "Nombre",
          value: "nombreCompleto",
          sortable: false,
          filterable: false,
        },
        {
          text: "",
          value: "calificacion",
          sortable: false,
          filterable: false,
          // width: "200px"
        },
        {
          text: "",
          value: "documento",
          sortable: false,
          filterable: false,
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: this.alumnos.length,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },
    };
  },
  methods: {
    calificacionAgregada(calificacion) {
      this.$emit("calificacionAgregada", calificacion);
    },
    calificacionModificada(calificacion) {
      this.$emit("calificacionModificada", calificacion);
    },
    calificacionModificadaArchivo(calificacion) {
      this.$emit("calificacionModificadaArchivo", calificacion);
    },
    seleccionarAlumno(idAlumno) {
      return this.alumnosLocal.find((alumno) => {
        return alumno._id === idAlumno;
      });
    },
    seleccionarDocumentosAlumno(idAlumno) {
      let alumno = this.alumnosLocal.find((alumno) => {
        return alumno._id === idAlumno;
      });
      return alumno.documentos;
    },
  },
};
</script>
