import { getServerRequest, putServerRequest, patchServerRequest, deleteServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function crearEquipoActividadService( idCurso, idActividad, nombre, alumnos ) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const data = { nombre, alumnos }
    const url = `${httpURL}/curso/campus/actividad/equipo/${idCurso}/${idActividad}`;
    return await putServerRequest(url, data, config);
}

export async function modificarEquipoActividadService( idCurso, idActividad, idEquipo, nombre, alumnos, nuevoAdmin ) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const data = { nombre, alumnos, nuevoAdmin }
    const url = `${httpURL}/curso/campus/actividad/equipo/${idCurso}/${idActividad}/${idEquipo}`;
    return await patchServerRequest(url, data, config);
}

export async function eliminarEquipoActividadService( {idCurso, idActividad, idEquipo} ) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/equipo/${idCurso}/${idActividad}/${idEquipo}`;
    return await deleteServerRequest(url, config);
}

export async function importarEquiposActividadService( idCurso, idActividad, data ) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/equipo/importar/${idCurso}/${idActividad}`;
    return await putServerRequest(url, data, config);
}
