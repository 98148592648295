import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('datatable',{attrs:{"headers":_vm.headers,"pagination":_vm.pagination,"datos":_vm.alumnosLocal,"itemsPerPage":_vm.alumnosLocal.length,"totalItems":_vm.alumnosLocal.length,"hideSearch":"","hidePagination":""},scopedSlots:_vm._u([{key:"item-avatar",fn:function(ref){
var value = ref.value;
return [_c(VAvatar,{attrs:{"color":"primary","size":"30"}},[(!value)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":(_vm.imageURL + "/avatar/" + value),"alt":"UserImage"}})],1)]}},{key:"item-calificacion",fn:function(ref){
var item = ref.item;
return [(_vm.tipoActividad == 1)?_c('calDirectaItem',{attrs:{"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"instrumento":_vm.instrumento,"tipoInstrumento":_vm.tipoInstrumento,"alumnos":_vm.alumnosLocal,"idAlumno":item._id,"calificaciones":item.calificaciones,"nombre":item.nombreCompleto,"permisoEscritura":_vm.permisoEscritura},on:{"calificacionAgregada":_vm.calificacionAgregada,"calificacionModificada":_vm.calificacionModificada}}):_vm._e(),(_vm.tipoActividad == 2)?_c('calArchivoItem',{attrs:{"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"disponible":_vm.disponible,"instrumento":_vm.instrumento,"tipoInstrumento":_vm.tipoInstrumento,"alumnos":_vm.alumnosLocal,"idAlumno":item._id,"nombre":item.nombreCompleto,"alumno":_vm.seleccionarAlumno(item._id),"documentos":_vm.seleccionarDocumentosAlumno(item._id),"permisoEscritura":_vm.permisoEscritura},on:{"calificacionModificadaArchivo":_vm.calificacionModificadaArchivo}}):_vm._e()]}},(_vm.tipoActividad == 2)?{key:"item-documento",fn:function(ref){
var item = ref.item;
return [_c('documentoItem',{attrs:{"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"disponible":_vm.disponible,"instrumento":_vm.instrumento,"tipoInstrumento":_vm.tipoInstrumento,"alumnos":_vm.alumnosLocal,"idAlumno":item._id,"nombre":item.nombreCompleto,"alumno":_vm.seleccionarAlumno(item._id),"documentos":_vm.seleccionarDocumentosAlumno(item._id),"permisoEscritura":_vm.permisoEscritura}})]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }