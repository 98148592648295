<template>
  <v-dialog v-model="mostrar"  fullscreen>
    <v-card outlined>
      <v-toolbar dense color="primary" dark flat class="text-h6">
        <v-btn icon @click="prev" :disabled="indexUsuario === 0 || indexEquipo === 0">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <strong v-if="!editarLocal"> Agregar calificación</strong>
        <strong v-else> Modificar calificación</strong>
        <v-spacer></v-spacer>
        <v-btn icon @click="next" :disabled="indexUsuario === alumnos.length-1 || indexEquipo === equipos.length-1">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-toolbar>

      <datosCalificacionComponent 
        :loading="loading"
        :instrumento="instrumento"
        :alumnos="alumnos"
        :equipos="equipos"
        :nombre="nombreLocal"
        :indexEquipo="indexEquipo"
        :calificacion="calificacionLocal"
        :observaciones="observacionesLocal"
        :editar="editarLocal"
        :bloquearBtn="bloquearModificar"
        @cancelar="$emit('cancelar')"
        @agregarCalificacion="agregarCalificacion"
      />

      <v-container>
        <v-card outlined>
          <rubricaActividadViewComponent 
            :esCalificacion="esCalificacion"
            :instrumentoActividad="instrumento"
            :selectedCalificacion="indicadores"
            @calificacionActualizada="calificacionActualizada"
          />
        </v-card>
      </v-container> 
  
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import datosCalificacionComponent from "../datosCalificacion.vue";
import rubricaActividadViewComponent from "../../../../../instrumentos/actividad/rubricaActividad.view.vue";
import { agregarCalificacionDirectaService, modificarCalificacionDirectaService } from "../../entregas.service";

export default {
  name: "agregarCalificacionRubrica",
  props: {
    mostrar: Boolean,
    idCurso: String,
    idActividad: String,
    tipoActividad: Number,
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    alumnos: { type: Array, default: () => [] },
    equipos: { type: Array, default: () => [] },
    idAlumno: String,
    idEquipo: String,
    nombre: String,
    editar: { type: Boolean, default: false },
    calificacion: { type: Number || null, default: "" },
    observaciones: { type: String || null, default: "" },
  },
  computed: {
    ...mapGetters(["app"]),
    bloquearModificar() {
      let blockBtn = false;
      let calificacionesData = [];
      if (this.alumnos.length != 0) 
        calificacionesData = this.alumnos[this.indexUsuario].calificaciones;
      if (this.equipos.length != 0) 
        calificacionesData = this.equipos[this.indexEquipo].calificaciones;
      if(this.editar && calificacionesData.length === 0) blockBtn = true;
      return blockBtn;
    }
  },
  data: () => ({
    loading: false,
    idCalificacion: null,
    calificacionLocal: 0,
    observacionesLocal: "",
    indicadores: [],
    // Alumnos
    indexUsuario: null,
    indexEquipo: null,
    idAlumnoLocal: null,
    idEquipoLocal: null,
    nombreLocal: null,
    // Instrumento
    esCalificacion: true,
    editarLocal: false,
  }),
  components: {
    rubricaActividadViewComponent,
    datosCalificacionComponent
  },
  watch:{
    indexUsuario() {
      this.setDataUsuario();
    },
    indexEquipo() {
      this.setDataEquipo();
    }
  },
  created() {
    if (this.alumnos.length != 0) {
      this.indexUsuario = this.alumnos.findIndex(alumno => alumno._id === this.idAlumno);
    }
    if (this.equipos.length != 0) {
      this.indexEquipo = this.equipos.findIndex(equipo => equipo._id === this.idEquipo);
    }
    this.editarLocal = this.editar;
  },
  methods: {
    setDataUsuario() {
      this.idAlumnoLocal = this.alumnos[this.indexUsuario]._id;
      this.nombreLocal = this.alumnos[this.indexUsuario].nombreCompleto;
      if(this.editarLocal && this.alumnos[this.indexUsuario].calificaciones.length != 0 || this.editar && this.alumnos[this.indexUsuario].calificaciones.length != 0) {
        this.idCalificacion = this.alumnos[this.indexUsuario].calificaciones[0]._id;
        this.observacionesLocal = this.alumnos[this.indexUsuario].calificaciones[0].observaciones;
        this.calificacionLocal = this.alumnos[this.indexUsuario].calificaciones[0].calificacion;
        this.indicadores = this.alumnos[this.indexUsuario].calificaciones[0].indicadores;
      }
    },
    setDataEquipo() {
      this.idEquipoLocal = this.equipos[this.indexEquipo]._id;
      this.nombreLocal = this.equipos[this.indexEquipo].nombre;
      if(this.editarLocal && this.equipos[this.indexEquipo].calificaciones.length != 0 || this.editar && this.equipos[this.indexEquipo].calificaciones.length != 0) {
        this.idCalificacion = this.equipos[this.indexEquipo].calificaciones[0]._id;
        this.observacionesLocal = this.equipos[this.indexEquipo].calificaciones[0].observaciones;
        this.calificacionLocal = this.equipos[this.indexEquipo].calificaciones[0].calificacion;
        this.indicadores = this.equipos[this.indexEquipo].calificaciones[0].indicadores;
      }
    },
    prev(){
      if (this.alumnos.length != 0) this.indexUsuario--;
      if (this.equipos.length != 0) this.indexEquipo--;
      this.calificacionLocal = 0;
      this.observacionesLocal = "";
      this.indicadores = [];
    },
    next(){
      if (this.alumnos.length != 0) this.indexUsuario++;
      if (this.equipos.length != 0) this.indexEquipo++;
      this.calificacionLocal = 0;
      this.observacionesLocal = "";
      this.indicadores = [];
    },
    calificacionActualizada(data){
      this.calificacionLocal = data.calificacion;
      this.indicadores = data.indicadores;
    },
    async agregarCalificacion(data) {
      try {
        let sendData = {
          calificacion: data.calificacion,
          observaciones: data.observaciones,
          indicadores: this.indicadores
        };

        if (this.alumnos) sendData.idAlumno = this.idAlumnoLocal;
        if (this.equipos) sendData.idEquipo = this.idEquipoLocal;

        this.loading = true;
        let serverResponse = null;
        if (!this.editarLocal) {
          serverResponse = await agregarCalificacionDirectaService(
            this.idCurso,
            this.idActividad,
            sendData
          );
        } else {
          serverResponse = await modificarCalificacionDirectaService(
            this.idCurso,
            this.idActividad,
            this.idCalificacion,
            sendData
          )
        } 
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          if(!this.editarLocal)
            this.$emit("calificacionAgregada", serverResponse.calificacion);
          else {
            this.editarLocal = true;
            this.$emit("calificacionModificada", serverResponse.calificacion);
          }
        } 
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>