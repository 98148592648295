<template>
  <v-container>
    <v-card-text v-if="calificaciones.length == 0"
      >No se ha calificado la actividad</v-card-text
    >
    <template v-else>
      <v-list-item
        v-for="calificacion in calificacionesLocal"
        :key="calificacion._id"
      >
        <v-list-item-avatar>
          <v-avatar color="indigo" size="36">
            <span class="white--text text-strong">{{
              calificacion.calificacion
            }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>{{ calificacion.fecha }}</v-list-item-subtitle>
          <textSubtractComponent
            v-if="calificacion.observaciones"
            :texto="calificacion.observaciones"
          />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import textSubtractComponent from "../../../widgets/textSubtract.vue";

export default {
  props: {
    idCurso: { type: String, default: null },
    actividad: { type: Object, default: () => ({}) },
    idUsuario: { type: String, default: null },
    calificaciones: { type: Array, default: () => [] },
  },
  components: { textSubtractComponent },
  computed: {
    calificacionesLocal() {
      const calificaciones = [];
      this.calificaciones.forEach((calificacion) => {
        let nuevaCalificacion = { ...calificacion };
        nuevaCalificacion.fecha = DateTime.fromISO(
          calificacion.fecha
        ).toLocaleString(DateTime.DATE_FULL);
        calificaciones.push(nuevaCalificacion);
      });
      return calificaciones;
    },
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>
