<template>
  <v-container>
		<v-row>
			<!-- Datos de perfil -->
			<v-col v-if="!calGeneral" cols="12" md="6" sm="12" class="justify-center text-center">
				<v-card outlined>
					<v-avatar size="80" style="margin-top: 10px">
						<v-icon size="100" v-if="this.alumnos.length != 0">
							mdi-account-circle
						</v-icon>
						<v-icon size="90" v-if="this.equipos.length != 0">
							mdi-account-group
						</v-icon>
					</v-avatar>
					<v-col class="justify-center text-center" cols="12">
							<strong>{{ nombre }}</strong>
							<v-list v-if="this.equipos.length != 0" dense>
								<v-divider></v-divider>
								<v-list-item 
									v-for="integrante in equipos[indexEquipo].integrantes" 
									:key="integrante._id"
									style="justify-content: center"
								>
									<v-icon v-if="integrante.admin" color="#dfec00" small class="mr-2">mdi-star</v-icon>
									<span>{{integrante.nombreCompleto}}</span>
								</v-list-item>
								<v-divider></v-divider>
							</v-list>
					</v-col>
				</v-card>
			</v-col>
			<!-- Datos de calificación -->
			<v-col :cols="calGeneral?'':'12'" :md="calGeneral?'': '6'" :sm="calGeneral?'':'12'">
				<v-card outlined>
					<v-card-title style="padding-bottom: 5px; padding-top: 0px; justify-content: center; background-color: #F8F8FF;">Calificación</v-card-title>
					<v-divider></v-divider>
					<v-container style="padding-bottom: 2px;">
						<v-row>
							<v-col cols="12" md="6" sm="12" style="padding-bottom: 0px; padding-top: 10px">
								<v-select
									v-if="!instrumento"
									v-model="calificacionLocal"
									label="Cuantitativa:"
									:items="calificaciones"
									outlined
									dense
								/>
								<v-text-field
									v-else
									label="Cualitativa:"
									:value="calificacionLocal"
									readonly
									outlined
									dense
								/>
							</v-col>
							<v-col cols="6" style="padding-bottom: 0px; padding-top: 10px">
								<v-textarea 
									v-model="observacionesLocal"
									label="Retroalimentación:"
									rows="3"
									outlined
									dense
								/>
							</v-col>
						</v-row>
					</v-container>

					<v-card-actions style="padding-top: 1px;">
							<v-spacer></v-spacer>
							<v-btn color="primary" 
								small 
								outlined 
								:disabled="loading" 
								@click="$emit('cancelar')">Cancelar</v-btn>
							<v-btn 
								color="primary"  
								small
								dark
								:class="{ 'disable-button': loading || bloquearBtn || tipoCalificacion}"
								@click="guardar()">
									<span v-if="!editar">Agregar</span>
									<span v-else>Modificar</span>
								</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

export default {
  name: "datosCalificacion",
  props: {
		calGeneral: { type: Boolean, default: false},
		tipo: { type: String, default: ''},
		loading: { type: Boolean, default: false },
		instrumento: { type: String, default: null },
		alumnos: { type: Array, default: () => [] },
		equipos: { type: Array, default: () => [] },
		nombre: { type: String, default: "" },
		documentos: { type: Array, default: () => [] },
		indexEquipo: { type: Number, default: null },
		calificacion: { type: Number, default: 0},
		observaciones: { type: String, default: ""},
		// Calificar sin instrumento
		calificaciones: { type: Array, default: () => [] },
		editar: { type: Boolean, default: false },
		bloquearBtn: { type: Boolean, default: false },
  },
	computed: {
		tipoCalificacion () {
			let btn = false;
			if (this.tipo === 'Archivo') return this.documentos.length > 0 ? btn = false : btn = true;
			return btn;
		}
	},
	data: () => ({
		calificacionLocal: null,
		observacionesLocal: "",
	}),
	created() {
		this.calificacionLocal = this.calificacion;
		this.observacionesLocal = this.observaciones;
	},
	watch: {
		nombre() {
			this.calificacionLocal = this.calificacion;
			this.observacionesLocal = this.observaciones;
		},
		calificacion() {
			this.calificacionLocal = this.calificacion;
		},
	},
	methods: {
		guardar() {
			let data = { calificacion: this.calificacionLocal, observaciones: this.observacionesLocal };
			this.$emit('agregarCalificacion', data);
		}
	}
}
</script>

<style>
.disable-button {
  pointer-events: none;
  opacity: 0.2;
}
</style>