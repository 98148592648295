<template>
	<v-card outlined>            
		<v-card-title style="margin: 0px" class="justify-center text-center">Archivos</v-card-title>
		<v-divider></v-divider>
		<v-card-text v-if="productos.length == 0"> No se ha entregado archivo.</v-card-text>
    <!-- NUEVO -->
      <v-container v-else fluid>
        <v-card outlined v-for="producto in productosLocal" :key="producto._id">
          <v-row>
            <!-- COLUMNA CON IMAGEN Y DESCRIPCIÓN -->
            <v-col cols="2">
              <v-container>
                <v-list-item-group>
                  <v-list-item-avatar :color="producto.color" style="margin: 0px">
                      <v-icon color="white">{{producto.icon}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-subtitle style="align-text: center">{{producto.tipo}}</v-list-item-subtitle>
                </v-list-item-group>
            </v-container>
            </v-col>

            <!-- COLUMNA CON INFORMACIÓN Y REENVÍO -->
            <v-col cols="3">
              <v-container>
                <v-list-item-group>
                  <v-list-item-title>
                    <v-tooltip left>
                      <template v-slot:activator="{on}">
                          <v-icon v-on="on" small @click="descargarProducto(producto)" color="grey" style="margin-right: 5px;">mdi-download</v-icon>
                      </template>
                      <span>Descargar</span>
                    </v-tooltip>
                    <!-- LIMPIO -->
                    <a class="link" href="javascript: void(0)" @click="mostrarArchivo(producto)">
                      {{producto.nombre}}
                    </a>
                  </v-list-item-title>
									<v-chip
										class="mx-1"
										x-small
										outlined
									>{{ producto.extension }}</v-chip>
                  <v-list-item-subtitle>Entregado {{producto.fecha}}</v-list-item-subtitle> 
                  <v-list-item-subtitle>Tamaño: {{producto.size}}</v-list-item-subtitle>
                </v-list-item-group>
              </v-container>
            </v-col>

            <!-- COLUMNA CON CALIFICACIÓN -->
            <v-col cols="4">
              <v-container>
                <v-list-item-group>
                  <v-list-item-title><strong>Calificación</strong></v-list-item-title>
                  <v-list-item-subtitle v-if="producto.entregaExt">
                    <v-chip label x-small color="#F57C00" outlined><v-icon left>mdi-alert</v-icon><i>Entrega extemporánea.</i></v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="(!producto.calificacion && typeof(producto.calificacion) != 'number')">
                    <v-chip label x-small color="blue-grey lighten-1" outlined><v-icon left>mdi-pen</v-icon><i>En espera de calificación</i></v-chip>
                  </v-list-item-subtitle>
                  <v-chip v-if="typeof(producto.calificacion) == 'number'" label large color="green darken-3" outlined style="font-size: 20px; height: 50px;">{{ producto.calificacion }}</v-chip>  
                  {{ producto.retro }}
                </v-list-item-group>
              </v-container>
            </v-col>

            <v-col cols="3">
              <v-container>
                <v-list-item-group v-if="productoActual._id === producto._id && productoActual.calificacion !== 100" >
                  <v-list-item-title>
                    <v-icon v-if="solicitaRevision" :disabled="(typeof(producto.calificacion) != 'number')" @click="solicitaRevisionAction()" large color="green" style="margin-left: 18px; margin-right: 15px;">mdi-toggle-switch</v-icon>
                    <v-icon v-else @click="solicitaRevisionAction()" :disabled="(typeof(producto.calificacion) != 'number')" large color="grey" style="margin-left: 18px; margin-right: 15px;">mdi-toggle-switch-off</v-icon>
                  </v-list-item-title>
				  				<v-chip v-if="(typeof(producto.calificacion) != 'number')" label x-small color="red lighten-1" outlined><v-icon left>mdi-alert</v-icon>Primero agregue una calificación</v-chip>
                  <p>Solicitar revisión y reenvío del archivo.</p>
                </v-list-item-group>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

		<v-card>
			<viewer
				v-if="verArchivo.mostrar"
				:mostrar="verArchivo.mostrar"
				:idRecurso="verArchivo.idRecurso"
				:nombreArchivo="verArchivo.filename"
				:url="verArchivo.url"
				:tipoArchivo="verArchivo.tipo"
				:permisos="verArchivo.permisos"
				@cerrar="verArchivo.mostrar=false"
		/>
		</v-card>
	</v-card>	
</template>

<script>

import { mapGetters } from "vuex";
import moment from 'moment-timezone'
import numeral from 'numeral'
import { getTypeFormatByName } from "../../../../../../helpers/utilerias";
import viewer from "../../../../../widgets/fileViewer.vue";

export default {
    name: 'listaArchivos',
    props:{
			idCurso: { type: String, default: () => "" },
			idActividad: { type: String, default: () => "" },
			productos: { type: Array, default: () => [] },
			disponible: { type: Object, default: () => {} },
			soloLectura: { type: Boolean, default: () => false },
		},
		components: {
			viewer,
		},
    computed: {
      ...mapGetters(["httpURL", "sessionToken", "userId","timezone", "documentsURL"]),
      productosLocal(){
        let productos = [];

				if(this.productos.length > 0) {
					this.productos.forEach(producto => {
						let item = {
							_id: producto._id,
							nombre: producto.file.name,
							extension: getTypeFormatByName(producto.file.name),
							fecha: moment(producto.fecha).locale('es').fromNow(),
							size: numeral(producto.file.size).format('0.00b'),
							url: producto.file.path,
							calificacion: producto.revision.calificacion,
							calificacionColor: producto.revision.calificacion < 60 ? '#D32F2F': '#000',
							retro: producto.revision.observaciones,
							entregaExt: this.disponible.sinFecha ? false :
									moment.tz(producto.fecha,this.timezone) > moment.tz(this.disponible.fin,this.timezone)
						}
						let file = producto.file.name.split('.');
						switch(file[file.length-1].toLowerCase()){
							case 'pdf':  {
								item.icon = 'mdi-file-pdf-box';
								item.color = 'red'; 
								item.tipo='PDF';
								item.tipoArchivo='application/pdf';
								break;
							}
							case 'doc': {
								item.icon = 'mdi-file-word'; 
								item.color = 'light-blue darken-2';
								item.tipo='Word';
								break;
							}
							case 'docx': {
								item.icon = 'mdi-file-word';
								item.color = 'light-blue darken-2';
								item.tipo='Word';
								break;
							}
							case 'xls': {
								item.icon = 'mdi-file-excel';
								item.color = 'green darken-2';
								item.tipo='Excel';
								break;
							}
							case 'xlsx': {
								item.icon = 'mdi-file-excel';
								item.color = 'green darken-2';
								item.tipo='Excel';
								break;
							}
							case 'ppt': {
								item.icon = 'mdi-file-powerpoint';
								item.color = 'orange darken-3';
								item.tipo='PPT';
								break;
							}
							case 'pptx': {
								item.icon = 'mdi-file-powerpoint';
								item.color = 'orange darken-3';
								item.tipo='PPT';
								break;
							}
							case 'zip': {
								item.icon = 'mdi-file-powerpoint';
								item.color = 'yellow darken-2';
								item.tipo='ZIP';
								break;
							}
							case 'jpg': {
								item.icon = 'mdi-file-image-outline';
								item.color = 'yellow darken-2';
								item.tipo='Imagen';
								item.tipoArchivo='image';
								break;
							}
							case 'jpeg': {
								item.icon = 'mdi-file-image-outline';
								item.color = 'indigo darken-2';
								item.tipo='Imagen';
								break;
							}
							case 'png': {
								item.icon = 'mdi-file-image-outline';
								item.color = 'indigo darken-2';
								item.tipo='Imagen';
								break;
							}
							case 'gif': {
								item.icon = 'mdi-file-image-outline';
								item.color = 'indigo darken-2';
								item.tipo='Imagen';
								break;
							}
							default: 
								item.icon = 'mdi-file';
								item.color = 'grey';
								item.tipo = 'Otro';
								break;
							
						}

						//item.icon = 'mdi-file-alert';
						//item.color = 'grey darken-2';
						productos.push(item);
					});
					//console.log("Productos: ", productos);
				}
      	return productos;
    	},
      reenvio(){
				let reenvio = false;
				if( this.productos.length > 0){
					let producto = this.productos[0];
					reenvio = producto.reenvio
				}
				return reenvio;
			},
			productoActual() {
      		if (this.productosLocal.length != 0) return this.productosLocal[0];
      		return false;
			}
    },
    watch:{
      productos(){
				if(this.productos.length > 0) {
        	let producto = this.productos[0];
        	this.solicitaRevision = producto.revision.reenvio;
				}
      }
    },
    data: () => ({
			loading: false,
			solicitaRevision: false,
			verArchivo:{
					mostrar:false,
					idRecurso: null,
					filename: null,
					url: null,
					tipo: null,
					permisos: {
          	descarga: true
        	},
			}
    }),
    mounted(){
		if( this.productos.length > 0){
			let producto = this.productos[0];
			this.solicitaRevision = producto.revision.reenvio;
			this.$emit('solicitaRevisionAction',this.solicitaRevision);
		}
    },
    methods:{
			solicitaRevisionAction(){
				if( !this.soloLectura ){

					this.solicitaRevision = !this.solicitaRevision;
					this.$emit('solicitaRevision',this.solicitaRevision);
				}
			},
      mostrarArchivo(producto){
        let url = `${this.documentsURL}/${producto.url}`;
        this.verArchivo.mostrar = true;
				this.verArchivo.idRecurso = producto._id;
        this.verArchivo.filename = producto.nombre;
        this.verArchivo.url = url;
        this.verArchivo.tipo = producto.tipoArchivo;
      },
      async descargarProducto(producto) {
				try {

					const url = `${this.documentsURL}/${producto.url}`;
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", '');

					link.click();
					link.remove();
					
				} catch (error) {
					this.loading = false;
          this.$appErrorMessage();
				}
      },
    }
}
</script>

<style>
    .link {
        text-decoration: none;
    }
    .avatar-list{
        margin-top: -10px;
    }
    .avatar-text{
        position: absolute;
        width: 45px;
        bottom: 10px;
        font-family:"Roboto";
        font-size: 12px;
        text-align: center;
    }
    .retro{
        padding-top: 1px;
        padding-bottom: 1px;
        text-align: justify;
    }
    .revision{
        padding-left: 5px;
        margin-top: 15px;
        margin-bottom: 5px;
        margin-left: 15px;
        margin-right: 15px;
    }
</style>