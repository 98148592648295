<template>
  <div>
    <v-list-item dense style="max-width: 500px">

      <v-row>
        <v-col>

          <div class="d-flex justify-center mt-2">
            <v-menu offset-x left close-delay>
              <template v-slot:activator="{ on, attrs }">

                <v-btn
                  v-if="calificaciones.length != 0"
                  color="primary"
                  outlined
                  v-bind="attrs" 
                  v-on="on"
                >
                  {{ calificacionActual.calificacion }}
                </v-btn>

                <v-btn
                  v-else
                  color="orange darken-1"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ><v-icon>mdi-alert-box</v-icon></v-btn>

              </template>

              <calificacionMenuComponent 
                :disableAdd="disableAddCal"
                :disableModify="disableModifyCal"
                :disableShow="disableShowCal"
                @addScore="agregarCalificacion = true"
                @modifyScore="modificarCalificacion(calificacionActual)"
                @showScore="verDetalles = true"
              />

            </v-menu>
          </div>

        </v-col>

        <v-col>
          <v-container>
            <v-list-item-content>
              <v-list-item-subtitle style="width: 100px;">{{calificacionActual.observaciones}}</v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-chip v-if="calificaciones.length == 0" label x-small color="amber darken-1" text-color="grey darken-1"  outlined><v-icon left>mdi-pen</v-icon>No se ha calificado la actividad.</v-chip>
                <v-chip v-else label x-small color="primary" text-color="grey darken-1" outlined><v-icon left>mdi-check</v-icon>Calificado</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-container>
        </v-col>

      </v-row>
    </v-list-item>

    <detallesCalificacionComponent
      v-if="verDetalles"
      :mostrar="verDetalles"
      :calificaciones="calificaciones"
      @cerrar="verDetalles = false"
    />

    <agregarCalificacionComponent
      v-if="agregarCalificacion && !instrumento"
      :mostrar="agregarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipoActividad="tipoActividad"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      :alumnos="this.alumnos"
      :equipos="this.equipos"
      :idAlumno="idAlumno"
      :idEquipo="idEquipo"
      :nombre="nombre"
      :editar="editarCalificacion"
      :calificacion="calificacionModificar"
      :observaciones="observacionesModificar"
      @cancelar="
        agregarCalificacion = false;
        editarCalificacion = false;
      "
      @calificacionAgregada="calificacionAgregada"
      @calificacionModificada="calificacionModificada"
    />

    <agregarCalificacionListaCotejoComponent
      v-if="agregarCalificacion && tipoInstrumento == 'listaCotejo'"
      :mostrar="agregarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipoActividad="tipoActividad"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      :alumnos="this.alumnos"
      :equipos="this.equipos"
      :idAlumno="idAlumno"
      :idEquipo="idEquipo"
      :nombre="nombre"
      :editar="editarCalificacion"
      :idCalificacion="idCalificacion"
      :calificacion="calificacionModificar"
      :observaciones="observacionesModificar"
      @cancelar="
        agregarCalificacion = false;
        editarCalificacion = false;
      "
      @calificacionAgregada="calificacionAgregada"
      @calificacionModificada="calificacionModificada"
    />

    <agregarCalificacionRubricaComponent
      v-if="agregarCalificacion && tipoInstrumento == 'rubrica'"
      :mostrar="agregarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipoActividad="tipoActividad"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      :alumnos="this.alumnos"
      :equipos="this.equipos"
      :idAlumno="idAlumno"
      :idEquipo="idEquipo"
      :nombre="nombre"
      :editar="editarCalificacion"
      :idCalificacion="idCalificacion"
      :calificacion="calificacionModificar"
      :observaciones="observacionesModificar"
      @cancelar="
        agregarCalificacion = false;
        editarCalificacion = false;
      "
      @calificacionAgregada="calificacionAgregada"
      @calificacionModificada="calificacionModificada"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import agregarCalificacionComponent from "./calificacionEntregas/calDirecta/agregarCalificacion.vue";
import detallesCalificacionComponent from "./detallesCalificacion.vue";
import agregarCalificacionListaCotejoComponent from "./calificacionEntregas/calDirecta/agregarCalificacionListaCotejo.vue";
import agregarCalificacionRubricaComponent from "./calificacionEntregas/calDirecta/agregarCalificacionRubrica.vue";
import calificacionMenuComponent from "./calificacionMenu.vue";

export default {
  name: "calDirectaItem",
  props: {
    idCurso: { type: String || null, default: null },
    idActividad: { type: String || null, default: null },
    tipoActividad: { type: Number, default: 1 },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    alumnos: { type: Array, default: () => [] },
    equipos: { type: Array, default: () => [] },
    idAlumno: { type: String || null, default: null },
    idEquipo: { type: String || null, default: null },
    calificaciones: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
    nombre: String,
    esComentario: { type: Boolean, default: false },
  },
  components: {
    agregarCalificacionComponent,
    detallesCalificacionComponent,
    agregarCalificacionListaCotejoComponent,
    agregarCalificacionRubricaComponent,
    calificacionMenuComponent
  },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    calificacionActual() {
      let calificacion = {};

      if (this.calificaciones.length > 0) {
        calificacion = this.calificaciones[0];
      }
      return calificacion;
    },
    disableAddCal() {
      if (this.permisoEscritura && !this.vistaSeguimiento) return false;
      return true;
    },
    disableModifyCal() {
      if(typeof(this.calificacionActual.calificacion) === 'number' && this.permisoEscritura && !this.vistaSeguimiento) return false;
      return true;
    },
    disableShowCal() {
      return this.calificaciones.length === 0;
    }
  },
  data() {
    return {
      loading: false,
      agregarCalificacion: false,
      editarCalificacion: false,
      idCalificacion: null,
      calificacionModificar: null,
      observacionesModificar: null,
      verDetalles: false,
    };
  },
  methods: {
    calificacionAgregada(calificacion) {
      this.editarCalificacion = false;
      this.$emit("calificacionAgregada", calificacion);
    },
    calificacionModificada(calificacion) {
      this.editarCalificacion = false;
      this.$emit("calificacionModificada", calificacion);
    },
    modificarCalificacion(calificacion) {
      if(!this.vistaSeguimiento){
        this.editarCalificacion = true;
        this.idCalificacion = calificacion._id;
        this.calificacionModificar = calificacion.calificacion;
        this.observacionesModificar = calificacion.observaciones;
        this.agregarCalificacion = true;
      }
    },
  },
};
</script>
