import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"400","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{attrs:{"color":"danger","dense":"","flat":"","dark":""}},[_c('strong',[_vm._v(" Elimiar entrega")])]),_c(VContainer,[_c('strong',[_vm._v("¿Está seguro de eliminar el equipo?")]),_c('p',[_vm._v(" Con esta acción se eliminarán calificaciones y trabajos entregados en caso de haberlos. ")]),_c('i',[_vm._v("Esta acción no podrá revertirse.")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","outlined":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"small":"","color":"danger","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.eliminarDocumento()}}},[_vm._v(" Eliminar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }