<template>
  <v-dialog v-model="mostrar" width="400" persistent>
    <v-card :loading="loading">
      <v-toolbar color="danger" dense flat dark>
        <strong> Elimiar entrega</strong>
      </v-toolbar>
      <v-container>
        <strong>¿Está seguro de eliminar la entrega?</strong>
        <p>
          Con esta acción se eliminará el documento y no podrá ser calificado.
        </p>
        <i>Esta acción no podrá revertirse.</i>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          color="danger"
          dark
          :loading="loading"
          @click="eliminarDocumento()"
        >
          Eliminar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { mapGetters } from "vuex";

import { removeDocumentService } from "./calificacionesAlumno.service";

export default {
  name: "eliminarDocumentoAlumno",
  props: {
    mostrar: Boolean,
    idCurso: String,
    idActividad: String,
    idDocumento: String,
  },

  data: () => ({
    loading: false,
  }),
  methods: {
    async eliminarDocumento() {
      try {
        let sendData = {
          idActividad: this.idActividad,
          idCurso: this.idCurso,
          idDocumento: this.idDocumento,
        };
        this.loading = true;
        let serverResponse = await removeDocumentService(sendData);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else this.$emit("documentoEliminado", this.idDocumento);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
