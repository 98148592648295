<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="4" lg="4">
        <tipoActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :tipo="tipo"
          :permisoEscritura="permisoEscritura"
          @tipoModificado="tipoModificado"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <instrumentoActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :instrumento="instrumento"
          :tipoInstrumento="tipoInstrumento"
          :permisoEscritura="permisoEscritura"
          @instrumentoModificado="instrumentoModificado"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <entregaActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :tipo="tipo"
          :entrega="entrega"
          :equipos="equipos"
          :permisoEscritura="permisoEscritura"
          @entregaModificada="entregaModificada"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="4" lg="4">
        <criterioActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          tipoActividad="actividad"
          :criterioEvaluacion="criterioEvaluacion"
          :permisoEscritura="permisoEscritura"
          @criterioModificado="criterioModificado"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <ponderacionActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :ponderacion="ponderacion"
          :permisoEscritura="permisoEscritura"
          @ponderacionModificada="ponderacionModificada"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-list-item dense>
          <v-list-item-icon
            ><v-icon color="primary">mdi-chart-arc </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Puntos: {{ puntos }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col  v-if="tipo === 2" cols="12" sm="12" md="4" lg="4">
        <formatoArchivoActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :formatoArchivo="formatoArchivo"
          :permisoEscritura="permisoEscritura"
          @formatoArchivoModificado="formatoArchivoModificado"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import criterioActividadComponent from "./criterioActividad.vue";
import instrumentoActividadComponent from "./instrumentoActividad.vue";
import tipoActividadComponent from "./tipoActividad.vue";
import entregaActividadComponent from "./entregaActividad.vue";
import ponderacionActividadComponent from "./ponderacionActividad.vue";
import formatoArchivoActividadComponent from "./formatoArchivoActividad.vue";

export default {
  name: "propiedadesActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipo: Number,
    instrumento: [Object, null],
    tipoInstrumento: String,
    criterioEvaluacion: [Object, null],
    entrega: {
      type: Number,
      validator: function (value) {
        return value >= 1 && value <= 10;
      },
    },
    equipos: Array,
    ponderacion: {
      type: Number,
      validator: function (value) {
        return value >= -1 && value <= 100;
      },
    },
    puntos: Number,
    formatoArchivo: { type: Array, default: null },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    criterioActividadComponent,
    instrumentoActividadComponent,
    tipoActividadComponent,
    entregaActividadComponent,
    ponderacionActividadComponent,
    formatoArchivoActividadComponent
  },
  data() {
    return {
      nombreCriterio: "",
      modificarCriterioIcon: false,
    };
  },
  methods: {
    criterioModificado(data) {
      this.$emit("criterioModificado", data);
    },
    ponderacionModificada(data){
      this.$emit("ponderacionModificada", data);
    },
    tipoModificado(data) {
      this.$emit("tipoModificado", data);
    },
    entregaModificada(data) {
      this.$emit("entregaModificada", data);
    },
    instrumentoModificado(data) {
      this.$emit("instrumentoModificado", data);
    },
    formatoArchivoModificado(data) {
      this.$emit("formatoArchivoModificado", data);
    },
  },
};
</script>
