import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.documentoActual)?_c(VListItem,{attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c('i',[_vm._v("No se ha entregado la actividad.")])])],1)],1):_c(VListItem,[_c(VListItemGroup,[_c(VListItemContent,[(_vm.documentoActual.revision.observaciones)?_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.documentoActual.revision.observaciones))]):_vm._e(),_c(VListItemTitle,[_c('a',{staticClass:"link",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.mostrarArchivo(_vm.documentoActual._id, _vm.documentoActual.file)}}},[_vm._v(_vm._s(_vm.documentoActual.file.name))]),_c('span',{staticClass:"document-size"},[_vm._v(_vm._s(_vm.documentoSize(_vm.documentoActual)))])]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.documentoFecha(_vm.documentoActual))+" "),_c(VChip,{staticClass:"mx-1",attrs:{"x-small":"","outlined":""}},[_vm._v(_vm._s(_vm.documentoTipo))]),(_vm.documentoExt(_vm.documentoActual))?_c(VChip,{attrs:{"x-small":"","color":"orange darken-1","dark":""}},[_vm._v("Extem.")]):_vm._e()],1),_c(VListItemSubtitle,[(
              !_vm.documentoActual.revision.calificacion &&
              typeof _vm.documentoActual.revision.calificacion != 'number'
            )?_c(VChip,{attrs:{"label":"","x-small":"","color":"amber darken-1","text-color":"grey darken-1","outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-pen")]),_vm._v("No se ha calificado la actividad.")],1):_vm._e()],1),_c(VListItemSubtitle,[(_vm.documentoActual.revision.reenvio)?_c(VChip,{attrs:{"label":"","x-small":"","color":"orange darken-4","outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v("Se ha solicitado reenvio")],1):_vm._e()],1),_c(VListItemSubtitle,[(
              !_vm.documentoActual.revision.reenvio &&
              typeof _vm.documentoActual.revision.calificacion == 'number'
            )?_c(VChip,{attrs:{"label":"","x-small":"","color":"primary","text-color":"grey darken-1","outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v("Calificado")],1):_vm._e()],1)],1)],1)],1),(_vm.verDetalles)?_c('detallesCalificacionComponent',{attrs:{"mostrar":_vm.verDetalles,"calificaciones":_vm.historialCalificaciones},on:{"cerrar":function($event){_vm.verDetalles = false}}}):_vm._e(),_c(VCard,[(_vm.verArchivo.mostrar)?_c('viewer',{attrs:{"mostrar":_vm.verArchivo.mostrar,"idRecurso":_vm.verArchivo.idRecurso,"nombreArchivo":_vm.verArchivo.filename,"url":_vm.verArchivo.url,"tipoArchivo":_vm.verArchivo.tipo,"permisos":_vm.verArchivo.permisos},on:{"cerrar":function($event){_vm.verArchivo.mostrar = false}}}):_vm._e()],1),(
      (_vm.agregarCalificacion && !_vm.instrumento) ||
      (_vm.previsualizarDocumento && !_vm.instrumento)
    )?_c('agregarCalificacionArchivoComponent',{attrs:{"mostrar":_vm.agregarCalificacion || _vm.previsualizarDocumento,"mostrarCalificar":_vm.agregarCalificacion,"mostrarDocumento":_vm.previsualizarDocumento,"editarCalificacion":_vm.editarCalificacion,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"disponible":_vm.disponible,"instrumento":_vm.instrumento,"alumnos":this.alumnos,"equipos":this.equipos,"idAlumno":_vm.idAlumno,"idEquipo":_vm.idEquipo},on:{"cancelar":function($event){(_vm.agregarCalificacion = false),
        (_vm.editarCalificacion = false),
        (_vm.previsualizarDocumento = false)},"calificacionModificadaArchivo":_vm.calificacionModificadaArchivo}}):_vm._e(),(
      (_vm.agregarCalificacion && _vm.tipoInstrumento == 'listaCotejo') ||
      (_vm.previsualizarDocumento && _vm.tipoInstrumento == 'listaCotejo')
    )?_c('agregarCalificacionArchivoListaCotejoComponent',{attrs:{"mostrar":_vm.agregarCalificacion || _vm.previsualizarDocumento,"mostrarCalificar":_vm.agregarCalificacion,"mostrarDocumento":_vm.previsualizarDocumento,"editarCalificacion":_vm.editarCalificacion,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"disponible":_vm.disponible,"instrumento":_vm.instrumento,"alumnos":this.alumnos,"equipos":this.equipos,"idAlumno":_vm.idAlumno,"idEquipo":_vm.idEquipo},on:{"cancelar":function($event){(_vm.agregarCalificacion = false),
        (_vm.editarCalificacion = false),
        (_vm.previsualizarDocumento = false)},"calificacionModificadaArchivo":_vm.calificacionModificadaArchivo}}):_vm._e(),(
      (_vm.agregarCalificacion && _vm.tipoInstrumento == 'rubrica') ||
      (_vm.previsualizarDocumento && _vm.tipoInstrumento == 'rubrica')
    )?_c('agregarCalificacionArchivoRubricaComponent',{attrs:{"mostrar":_vm.agregarCalificacion || _vm.previsualizarDocumento,"mostrarCalificar":_vm.agregarCalificacion,"mostrarDocumento":_vm.previsualizarDocumento,"editarCalificacion":_vm.editarCalificacion,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"disponible":_vm.disponible,"instrumento":_vm.instrumento,"alumnos":this.alumnos,"equipos":this.equipos,"idAlumno":_vm.idAlumno,"idEquipo":_vm.idEquipo},on:{"cancelar":function($event){(_vm.agregarCalificacion = false),
        (_vm.editarCalificacion = false),
        (_vm.previsualizarDocumento = false)},"calificacionModificadaArchivo":_vm.calificacionModificadaArchivo}}):_vm._e(),(_vm.sinArchivo.mostrar)?_c('noFile',{attrs:{"mostrar":_vm.sinArchivo.mostrar,"nombreArchivo":_vm.sinArchivo.nombre},on:{"cerrar":function($event){_vm.sinArchivo.mostrar = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }