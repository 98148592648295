<template>
  <v-container>
    <v-card rounded="lg">
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer> {{ rubrica ? rubrica.nombre : "" }}
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text v-if="loadingRubrica" flat>
        <v-skeleton-loader type="list-item-avatar-one-line@5"></v-skeleton-loader>
      </v-card-text>

      <v-card-text v-else>
        <v-list-item v-if="!esCalificacion">
          <div
            v-html="descripcion || '<i>No se ha capturado descripción.</i>'"
          ></div>
        </v-list-item>

        <v-divider class="mx-3"></v-divider>
        <p class="text-center">
          <instrumentoStatusComponent :status="status" style="margin: 10px"/>
        </p>

        <!-- Secciones -->
        <v-skeleton-loader
          v-if="loadingSecciones"
          type="list-item-two-line@3"
          style="margin: 10px"
        ></v-skeleton-loader>

        <template v-else>
          <v-card shaped outlined style="margin: 10px" v-if="secciones.length === 0">
            <v-toolbar dense flat class="text-bold">
            <span class="text--secondary"
              >No se han agregado secciones a esta rubrica.</span>
          </v-toolbar>
          </v-card>

          <!-- EXISTEN SECCIONES -->
          <v-container v-for="seccion in secciones" :key="seccion._id">
            <v-card tile outlined class="card">
              <v-list-item dense flat class="seccion">
                <v-list-item-content>
                  <v-list-item-title>{{ seccion.nombre }}</v-list-item-title>
                  <v-list-item-subtitle v-if="seccion.pondeeracion >= 0">{{ seccion.ponderacion }}% - {{ seccion.puntos}} pts</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>Promedio - {{ seccion.puntos }} pts</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <!-- NIVELES LOGRO -->
              <v-container>
                <v-row no-gutters v-if="seccion.nivelesLogro.length == 0"
                  ><v-col class="SinNivelLogro"
                    >No se han generado niveles de logro.</v-col
                  ></v-row
                >

                <v-row v-else no-gutters>
                  <v-col class="tituloNivel" cols="3">
                    <v-card-subtitle>Niveles de logro</v-card-subtitle>
                  </v-col>
                    <v-col
                      v-for="nivel in seccion.nivelesLogro"
                      :key="nivel._id"
                      style="padding: 0px"
                      class="nivelLogro"
                    >
                      <v-toolbar flat color="#edf4fb">
                        <span style="margin-left: 10px">
                          {{ nivel.nombre }} ({{ nivel.ponderacion }}%)
                        </span>
                      </v-toolbar>
                    </v-col>
                </v-row>

                <v-divider></v-divider>
                <!-- CRITERIOS -->
                <v-skeleton-loader
                  v-if="loadingCriterios"
                  type="list-item-two-line@3"
                  style="margin: 10px"
                ></v-skeleton-loader>

                <v-container v-else class="col">
                  <v-row>
                    <v-row no-gutters v-if="seccion.criterios.length == 0" class="criterio">
                      No se han generado criterios.
                    </v-row>
                  </v-row>
                  <v-row
                    v-for="criterio in seccion.criterios"
                    :key="criterio._id"
                    class="criterio"
                  >
                    <v-col cols="3">
                      <div>{{ criterio.nombre }}</div>
                      <div>
                        <div
                          v-if="criterio.ponderacion >= 0"
                          class="text--secondary text-right"
                        >
                          <span>
                            {{ criterio.ponderacion }} % - {{ criterio.puntos }} pts
                          </span>
                        </div>
                        <div v-else class="text--secondary text-right">
                          <v-tooltip bottom color="#848484">
                            <template v-slot:activator="{ on }">
                              <span color="primary" dark v-on="on"> Promedio </span>
                            </template>
                            <span>{{ criterio.puntos }} pts</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-col>
                    <!-- Div -->
                    <v-col
                      v-for="indicador in criterio.indicadores"
                      :key="indicador._id"
                      :class="estaSeleccionado(indicador._id) ? 'active' : 'indicador'"
                      @click="seleccionado(criterio._id,indicador._id)"
                    >
                      <div>
                        <span v-if="indicador.descripcion">
                          {{ indicador.descripcion }}</span
                        >
                        <span v-else
                          ><font color="#F000"><i>No se ha capturado descripción.</i></font>
                        </span>
                      </div>
                      <div class="text-right">
                        <v-chip color="grey lighten-2" text-color="black" x-small>
                          <span> {{ indicador.puntos }} pts.</span>
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-container>
            </v-card>
          </v-container>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import instrumentoStatusComponent from "../instrumentoStatus.vue";

import { getRubricaActividadService, getSeccionesRubricaActividadService, getCriteriosRubricaActividadService } from "./actividadInstrumento.service.js";

export default {
  name: "rubricaActividad",
  props:{
      esCalificacion: { type: Boolean, default: false },
      instrumentoActividad: { type: String, default: "" },
      selectedCalificacion: { type: Array, default: () => [] }
  },
  components: {
    instrumentoStatusComponent,
  },
  computed: {
      ...mapGetters(["sessionToken"]),
  },
  watch: {
    sessionToken(value) {
      if (value) { 
        this.getInstrumento(); 
        this.getSecciones();
      }
    },
    selectedCalificacion(value) {
      this.selected = value;
    }
  },
  mounted(){
    this.getInstrumento();
    this.getSecciones();
    this.selected = this.selectedCalificacion;
  },
  data() {
      return {
        loadingRubrica: false,
        loadingSecciones: false,
        loadingCriterios: false,
        rubrica: null,
        status: "incompleto",
        descripcion: "",
        secciones: [],
        criterios: [],
        selected: [],
        calificacion: 0
      };
  },
  methods: {
    async getInstrumento() {
      try {
        this.loadingRubrica = true;
        const serverResponse = await getRubricaActividadService(this.instrumentoActividad);
        this.loadingRubrica = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$router.push("/instrumentos#listas");
        } else {
          this.rubrica = serverResponse.rubrica;
          this.status = serverResponse.rubrica.status;
          this.descripcion = serverResponse.rubrica.descripcion;
        }
      } catch (error) {
        this.loadingRubrica = false;
        this.$appErrorMessage();
      }
    },
    async getSecciones() {
      try {
        this.loadingSecciones = true;
        const serverResponse = await getSeccionesRubricaActividadService(this.instrumentoActividad);
        this.loadingSecciones = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.secciones = serverResponse.secciones;

          this.secciones.forEach(async(seccion, index) => {
            await this.getCriterios(seccion._id, index)
          })

        }
      } catch (error) {
        this.loadingSecciones = false;
        this.$appErrorMessage();
      }
    },
    async getCriterios(idSeccion, index) {
      try {
        this.loadingCriterios = true;
        const serverResponse = await getCriteriosRubricaActividadService(
          this.instrumentoActividad,
          idSeccion
        );
        this.loadingCriterios = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.secciones[index].criterios = serverResponse.criterios;
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
    seleccionado(idCriterio, idIndicador){
      //console.log("IdCriterio: ", idCriterio);
      //console.log("IdIndicador: ", idIndicador);
      if( this.esCalificacion ){
        //Se eliminan los indicadores seleccionados del criterio
        this.secciones.forEach(seccion => {
            let criterio = seccion.criterios.find(x=>x._id == idCriterio);
            if( criterio ){
                criterio.indicadores.forEach(indicador => {
                    let index = this.selected.findIndex(x=>x == indicador._id);
                    if( index >= 0)
                        this.selected.splice(index,1);
                });
            }
        });
        this.selected.push(idIndicador);
        //Se calcula la calificación
          this.calificacion = 0;
          this.secciones.forEach(seccion => {
              seccion.criterios.forEach(criterio => {
                  criterio.indicadores.forEach(indicador => {
                      let index = this.selected.findIndex(x=>x == indicador._id);
                      if( index >= 0 )
                          this.calificacion += indicador.puntos;
                  });
              });
          });
          if( this.calificacion > 99 )
              this.calificacion = 100;
          else
              this.calificacion = Math.round(this.calificacion);
  
          let data = { calificacion: this.calificacion, indicadores: this.selected};
          this.$emit('calificacionActualizada', data);
        }
    },
    estaSeleccionado(indicador){
      //console.log("Seleccionado indicador: ", indicador);
        let index = this.selected.findIndex(x=>x == indicador);
        return index >= 0;
    }

  },
}

</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
.card {
  transition: all 0.1s ease;
}

.card:hover {
  box-shadow: rgba(14, 84, 196, 0.2) 0px 2px 7px 0px;
}
.seccion {
  font-weight: bold;
  background-color: #f0f8ff;
}
.SinNivelLogro {
  text-align: center;
  background-color: #f0f8ff;
  border: 1px solid #fff;
}

.tituloNivel {
  /* text-align: center; */
  background-color: #e7eef4;
  border: 0.2px solid #fff;
}
.nivelLogro {
  /* text-align: center; */
  background-color: #edf4fb;
  border: 1px solid #fff;
  margin: 0%;
}
.criterio {
  justify-content: center;
  background-color: #e7eef4;
  border: 1px solid #fff;
}
.indicador {
  justify-content: center;
  text-align: center;
  background-color: #edf4fb;
  border: 1px solid #fff;
}
.active {
  justify-content: center;
  text-align: center;
  background-color: #81C784;
  border: 1px solid #fff;
}

</style>