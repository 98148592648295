<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="4">
        <v-list-item
          dense
          @mouseover="permisoEscritura && !vistaSeguimiento ? (modificarDisponibilidadIcon = true) : null"
          @mouseout="permisoEscritura ? (modificarDisponibilidadIcon = false) : null"
        >
          <v-list-item-icon>
            <v-btn
              v-if="modificarDisponibilidadIcon && !vistaSeguimiento"
              fab
              x-small
              color="primary"
              @click="modificarDisponibilidad = true"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-icon v-else color="primary">mdi-calendar-week </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Fecha de entrega
              <actividadDisponibleChip
                :disponible="disponible"
                :entregaExt="entregaExt"
              />
            </v-list-item-title>
            <v-list-item-subtitle>{{ fechaEntrega }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        v-if="!sesionCursoAlumno && !sesionCursoPadre && !vistaSeguimiento"
      >
        <v-list-item dense>
          <v-list-item-action
            ><v-checkbox
              v-model="extemporaneo"
              color="primary"
              :disabled="loadingExtemporaneo || !permisoEscritura"
              @change="cambiarExtemporaneo()"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-title>Entrega extemporánea</v-list-item-title>
        </v-list-item>
      </v-col>
      <v-col
        v-if="entrega > 1 && !sesionCursoAlumno && !sesionCursoPadre"
        cols="12"
        sm="12"
        md="4"
      >
        <v-list-item dense>
          <v-list-item-action
            ><v-checkbox
              v-model="formarEquiposLocal"
              color="primary"
              :disabled="loadingFormarEquipos || !permisoEscritura"
              @change="cambiarFormarEquipos()"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-title
            >Los alumnos pueden formar equipos</v-list-item-title
          >
        </v-list-item>
      </v-col>

      <v-col
        v-if="entrega > 1 && sesionCursoPadre || entrega > 1 && sesionCursoAlumno  && formarEquiposLocal"
        cols="12"
        sm="12"
        md="8"
      >
        <v-list-item dense class="d-flex justify-end mr-8">
          <v-alert outlined color="primary" style="font-size: 13px; height: 30px;  padding-top: 5px;">Los alumnos pueden formar equipos</v-alert>
        </v-list-item>
      </v-col>
    </v-row>

    <modificarDisponibilidadActividadComponent
      v-if="modificarDisponibilidad && permisoEscritura"
      :mostrar="modificarDisponibilidad"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :disponible="disponible"
      @cambioFechaEntrega="cambioFechaEntrega"
      @cancelar="modificarDisponibilidad = false"
    />
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import {
  modificarEntregaExtemporanea,
  modificarFormarEquipos,
} from "../actividad.service";
import modificarDisponibilidadActividadComponent from "./modificarDisponibilidadActividad.vue";
import actividadDisponibleChip from "../../../widgets/chips/actividadDisponibleChip.vue";

export default {
  name: "configuracionActividad",
  props: {
    idCurso: String,
    idActividad: String,
    permisoEscritura: { type: Boolean, default: false },
    entrega: {
      type: Number,
      default: 1,
      validator: function (value) {
        return value >= 1 && value <= 10;
      },
    },
    entregaExt: { type: Boolean, default: false },
    formarEquipos: { type: Boolean, default: false },
    disponible: {
      type: Object,
      default: function () {
        return { abierta: true, inicio: null, fin: null };
      },
    },
  },
  components: {
    modificarDisponibilidadActividadComponent,
    actividadDisponibleChip,
  },
  watch: {
    entregaExt(value) {
      this.extemporaneo = value;
    },
    formarEquipos(value) {
      this.formarEquiposLocal = value;
    },
  },
  computed: {
    ...mapGetters(["sesionCursoAlumno", "sesionCursoPadre", "vistaSeguimiento"]),
    fechaEntrega() {
      let fechaTexto = "Abierta";
      if (!this.disponible.abierta) {
        var fechaInicio = DateTime.fromISO(this.disponible.inicio);
        var fechaFin = DateTime.fromISO(this.disponible.fin);

        fechaTexto = `${fechaInicio.toLocaleString(
          DateTime.DATETIME_SHORT
        )} hrs. - ${fechaFin.toLocaleString(DateTime.DATETIME_SHORT)} hrs.`;
      }

      return fechaTexto;
    },
  },
  mounted() {
    this.extemporaneo = this.entregaExt;
    this.formarEquiposLocal = this.formarEquipos;
  },
  data() {
    return {
      loadingExtemporaneo: false,
      loadingFormarEquipos: false,
      extemporaneo: false,
      formarEquiposLocal: false,
      modificarDisponibilidadIcon: false,
      modificarDisponibilidad: false,
    };
  },
  methods: {
    async cambiarExtemporaneo() {
      try {
        this.loadingExtemporaneo = true;
        const serverResponse = await modificarEntregaExtemporanea(
          this.idCurso,
          this.idActividad
        );
        this.loadingExtemporaneo = false;
        if (!serverResponse.ok) {
          this.extemporaneo = !this.extemporaneo;
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("cambioExtemporaneo", serverResponse.entregaExt);
        }
      } catch (error) {
        this.extemporaneo = !this.extemporaneo;
        this.loadingExtemporaneo = false;
        this.$appErrorMessage();
      }
    },
    async cambiarFormarEquipos() {
      try {
        this.loadingFormarEquipos = true;
        const serverResponse = await modificarFormarEquipos(
          this.idCurso,
          this.idActividad
        );
        this.loadingFormarEquipos = false;
        if (!serverResponse.ok) {
          this.formarEquipos = !this.formarEquipos;
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("cambioFormarEquipos", serverResponse.formarEquipos);
        }
      } catch (error) {
        this.formarEquipos = !this.formarEquipos;
        this.loadingFormarEquipos = false;
        this.$appErrorMessage();
      }
    },
    cambioFechaEntrega(data) {
      this.modificarDisponibilidad = false;
      this.$emit("cambioFechaEntrega", data);
    },
  },
};
</script>
