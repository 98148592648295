<template>
  <div>
    <!-- CALIFICACION -->
    <v-row>
      <v-col>
        <!-- No cuenta con archivo -->
        <v-btn v-if="!documentoActual" disabled outlined
          ><v-icon>mdi-clock</v-icon>
        </v-btn>

        <!-- Se tiene archivo  -->
        <v-menu v-else offset-x left close-delay>
          <template v-slot:activator="{ on, attrs }">
            <!-- Se ha calificado -->
            <v-btn
              v-if="
                typeof documentoActual.revision.calificacion == 'number' ||
                documentoAnterior
              "
              outlined
              :color="
                documentoAnterior &&
                typeof documentoActual.revision.calificacion != 'number'
                  ? 'orange darken-1'
                  : 'primary'
              "
              v-bind="attrs"
              v-on="on"
            >
              {{ calificacionBtn() }}
            </v-btn>
            <!-- No se ha calificado -->
            <v-btn
              v-else
              outlined
              color="orange darken-1"
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-alert-box</v-icon></v-btn
            >
          </template>

          <calificacionMenuComponent 
            :disableAdd="disableAddCal"
            :disableModify="disableModifyCal"
            :disableShow="disableShowCal"
            @addScore="agregarCalificacion = true"
            @modifyScore="(editarCalificacion = true), (agregarCalificacion = true)"
            @showScore="verDetalles = true"
          />
        </v-menu>
      </v-col>
    </v-row>

    <detallesCalificacionComponent
      v-if="verDetalles"
      :mostrar="verDetalles"
      :calificaciones="historialCalificaciones"
      @cerrar="verDetalles = false"
    />

    <!-- CALIFICACIÓN -->
    <agregarCalificacionArchivoComponent
      v-if="
        (agregarCalificacion && !instrumento) ||
        (previsualizarDocumento && !instrumento)
      "
      :mostrar="agregarCalificacion || previsualizarDocumento"
      :mostrarCalificar="agregarCalificacion"
      :mostrarDocumento="previsualizarDocumento"
      :editarCalificacion="editarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :disponible="disponible"
      :instrumento="instrumento"
      :alumnos="this.alumnos"
      :equipos="this.equipos"
      :idAlumno="idAlumno"
      :idEquipo="idEquipo"
      @cancelar="
        (agregarCalificacion = false),
          (editarCalificacion = false),
          (previsualizarDocumento = false)
      "
      @calificacionModificadaArchivo="calificacionModificadaArchivo"
    />

    <!-- EMPEZAR -->

    <agregarCalificacionArchivoListaCotejoComponent
      v-if="
        (agregarCalificacion && tipoInstrumento == 'listaCotejo') ||
        (previsualizarDocumento && tipoInstrumento == 'listaCotejo')
      "
      :mostrar="agregarCalificacion || previsualizarDocumento"
      :mostrarCalificar="agregarCalificacion"
      :mostrarDocumento="previsualizarDocumento"
      :editarCalificacion="editarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :disponible="disponible"
      :instrumento="instrumento"
      :alumnos="this.alumnos"
      :equipos="this.equipos"
      :idAlumno="idAlumno"
      :idEquipo="idEquipo"
      @cancelar="
        (agregarCalificacion = false),
          (editarCalificacion = false),
          (previsualizarDocumento = false)
      "
      @calificacionModificadaArchivo="calificacionModificadaArchivo"
    />

    <agregarCalificacionArchivoRubricaComponent
      v-if="
        (agregarCalificacion && tipoInstrumento == 'rubrica') ||
        (previsualizarDocumento && tipoInstrumento == 'rubrica')
      "
      :mostrar="agregarCalificacion || previsualizarDocumento"
      :mostrarCalificar="agregarCalificacion"
      :mostrarDocumento="previsualizarDocumento"
      :editarCalificacion="editarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :disponible="disponible"
      :instrumento="instrumento"
      :alumnos="this.alumnos"
      :equipos="this.equipos"
      :idAlumno="idAlumno"
      :idEquipo="idEquipo"
      @cancelar="
        (agregarCalificacion = false),
          (editarCalificacion = false),
          (previsualizarDocumento = false)
      "
      @calificacionModificadaArchivo="calificacionModificadaArchivo"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import numeral from "numeral";

import agregarCalificacionArchivoComponent from "./calificacionEntregas/calArchivo/agregarCalificacionArchivo.vue";
import detallesCalificacionComponent from "./detallesCalificacion.vue";
import agregarCalificacionArchivoListaCotejoComponent from "./calificacionEntregas/calArchivo/agregarCalificacionArchivoListaCotejo.vue";
import agregarCalificacionArchivoRubricaComponent from "./calificacionEntregas/calArchivo/agregarCalificacionArchivoRubrica.vue";
import calificacionMenuComponent from "./calificacionMenu.vue";

import { validaExistenciaService } from "../../../recursos/recursos.service";

export default {
  name: "calArchivoItem",
  props: {
    idCurso: { type: String || null, default: null },
    idActividad: { type: String || null, default: null },
    disponible: { type: Object, default: () => {} },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    alumnos: { type: Array, default: () => [] },
    equipos: { type: Array, default: () => [] },
    idAlumno: { type: String || null, default: null },
    idEquipo: { type: String || null, default: null },
    permisoEscritura: { type: Boolean, default: false },
    nombre: String,
    alumno: { type: Object, default: () => {} },
    equipo: { type: Object, default: () => {} },
    documentos: { type: Array, default: () => [] },
  },
  components: {
    agregarCalificacionArchivoComponent,
    detallesCalificacionComponent,
    agregarCalificacionArchivoListaCotejoComponent,
    agregarCalificacionArchivoRubricaComponent,
    calificacionMenuComponent
  },
  computed: {
    ...mapGetters(["documentsURL", "vistaSeguimiento"]),
    documentoActual() {
      if (this.documentos.length != 0) return this.documentos[0];
      return false;
    },
    documentoAnterior() {
      if (this.documentos.length > 1) return this.documentos[1];
      return false;
    },
    historialCalificaciones() {
      const calificaciones = [];
      this.documentos.forEach((documento) => {
        if (typeof documento.revision.calificacion === "number") {
          calificaciones.push({
            _id: documento._id,
            calificacion: documento.revision.calificacion,
            fecha: documento.revision.fecha,
            observaciones: documento.revision.observaciones,
            nombreArchivo: documento.file.name,
          });
        }
      });

      return calificaciones;
    },
    disableAddCal() {
      if (
        typeof(this.documentoActual.revision.calificacion) != "number" &&
        !this.documentoActual.revision.calificacion &&
        this.permisoEscritura &&
        !this.vistaSeguimiento
      )
        return false;
      return true;
    },
    disableModifyCal() {
      if (
        typeof(this.documentoActual.revision.calificacion) === "number" && 
        this.permisoEscritura && !this.vistaSeguimiento
      ) return false;
      return true;
    },
    disableShowCal() {
      return this.historialCalificaciones.length === 0;
    },
  },
  data() {
    return {
      loading: false,
      agregarCalificacion: false,
      editarCalificacion: false,
      previsualizarDocumento: false,
      verDetalles: false,
      verArchivo: {
        mostrar: false,
        filename: null,
        url: null,
        tipo: null,
      },
      sinArchivo: { mostrar: false, nombre: null },
    };
  },
  created() {},
  methods: {
    calificacionBtn() {
      if (this.documentoActual) {
        if (typeof this.documentoActual.revision.calificacion != "number") {
          return this.documentoAnterior.revision.calificacion;
        } else {
          return this.documentoActual.revision.calificacion;
        }
      }
    },
    calificacionModificadaArchivo(calificacion) {
      this.editarCalificacion = false;
      this.$emit("calificacionModificadaArchivo", calificacion);
    },
    documentoSize(documento) {
      let size = numeral(documento.file.size).format("0.0b");
      return size;
    },
    documentoFecha(documento) {
      let fecha = DateTime.fromISO(documento.fecha).toLocaleString(
        DateTime.DATE_FULL
      );
      return fecha;
    },
    documentoExt(documento) {
      let fechaFin = !this.disponible.abierta
        ? DateTime.fromISO(this.disponible.fin)
        : null;

      let fechaDocumento = DateTime.fromISO(documento.fecha);
      let entregaExt = false;

      if (fechaFin) {
        entregaExt = fechaDocumento > fechaFin;
      }

      return entregaExt;
    },
    async mostrarArchivo(documento) {
      try {
        const serverResponse = await validaExistenciaService({
          url: documento.path,
        });
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.existe) {
          let url = `${this.documentsURL}/${documento.path}`;
          this.verArchivo.mostrar = true;
          this.verArchivo.filename = documento.name;
          this.verArchivo.url = url;
          this.verArchivo.tipo = documento.type;
        } else this.sinArchivo = { mostrar: true, nombre: documento.name };
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style scoped>
.document-size {
  margin-left: 5px;
  font-size: 12px;
  font-style: italic;
  color: gray;
}
</style>
