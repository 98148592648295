<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dense flat dark>
        <strong> Detalle calificaciones</strong>
      </v-toolbar>
      <v-container >
        <v-card
          v-for="calificacion in calificacionesLocal"
          :key="calificacion._id"
          outlined
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="indigo" size="36">
                <span class="white--text text-strong">{{
                  calificacion.calificacion
                }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>{{ calificacion.fecha }}</v-list-item-title>
            <v-list-item-subtitle v-if="calificacion.nombreArchivo" max-width="10px" style="text-align: center">{{ calificacion.nombreArchivo }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="mx-3"></v-divider>
          <v-card-text v-if="calificacion.observaciones">
            {{ calificacion.observaciones }}
          </v-card-text>
        </v-card>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small outlined color="primary" @click="$emit('cerrar')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: 'detallesCalificacion',
  props: {
    mostrar: { type: Boolean, default: false },
    calificaciones: { type: Array, default: () => [] },
  },
  computed: {
    calificacionesLocal() {
      const calificaciones = [];
      this.calificaciones.forEach((calificacion) => {
        let nuevaCalificacion = { ...calificacion };
        nuevaCalificacion.fecha = DateTime.fromISO(
          calificacion.fecha
        ).toLocaleString(DateTime.DATE_FULL);
        calificaciones.push(nuevaCalificacion);
      });
      return calificaciones;
    },
  },
};
</script>
