import { patchServerRequest, getServerRequest, putServerRequest, deleteServerRequest } from "../../../services/serverRequest.service";
import { store } from '../../../../../store/store';

const httpURL = store.getters.httpURL;

export const getListaCotejoActividadService = async (idListaCotejo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumentos/actividad/listaCotejo/${idListaCotejo}`;
    return await getServerRequest(url, config);
};

export const getSeccionesListaCotejoActividadService = async (idListaCotejo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumentos/actividad/listaCotejo/${idListaCotejo}/secciones`;
    return await getServerRequest(url, config);
}

export const getRubricaActividadService = async (idRubrica) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumentos/actividad/rubrica/${idRubrica}`;
    return await getServerRequest(url, config);
};

export const getSeccionesRubricaActividadService = async (idRubrica) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumentos/actividad/rubrica/${idRubrica}/secciones`;
    return await getServerRequest(url, config);
}

export const getCriteriosRubricaActividadService = async (idRubrica, idSeccion) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumentos/actividad/rubrica/${idRubrica}/criterios/${idSeccion}`;
    return await getServerRequest(url, config);
}