<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title>Modificar criterio</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-alert color="orange lighten-1" dense dark>
          Al modificar el criterio, la ponderación cambiará a "Promedio"
        </v-alert>
        <v-select
          v-model="criterioSelect"
          outlined
          dense
          label="Criterio:"
          item-value="_id"
          item-text="nombre"
          :disabled="loading || loading"
          :items="criterios"
          :loading="loading"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.nombre }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.seccion">{{
                item.seccion
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !esValido }"
          @click="modificarCriterio()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { obtenerEvaluacionCursoService } from "../../encuadre/evaluacion/evaluacion.service";
import { modificarCriterioService } from "../actividad.service";
export default {
  name: "modificarCriterioActividad",
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipoActividad: {
      type: String,
      enum: ["actividad", "examenAplicacion", "encuesta"],
    },
    idCriterio: String || null,
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    esValido() {
      return this.criterioSelect != this.idCriterio;
    },
  },
  data() {
    return {
      loading: false,
      criterioSelect: null,
      criterios: [],
    };
  },
  mounted() {
    this.cargarEvaluacion();
  },
  methods: {
    async cargarEvaluacion() {
      try {
        this.criterios = [{ _id: null, nombre: "Ninguno", seccion: "" }];
        this.loading = true;
        const serverResponse = await obtenerEvaluacionCursoService(
          this.tipoCurso,
          this.idCurso
        );
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          serverResponse.secciones.forEach((seccion) => {
            const criteriosValidos = seccion.criterios.filter(
              (x) => !x.asistencias.requerido
            );
            const criterios = criteriosValidos.map((criterio) => {
              return {
                _id: criterio._id,
                nombre: criterio.nombre,
                seccion: seccion.nombre,
              };
            });
            this.criterios.push(...criterios);
          });
          this.criterioSelect = this.idCriterio;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async modificarCriterio() {
      try {
        this.loading = true;
        const data = { idCriterio: this.criterioSelect };
        const serverResponse = await modificarCriterioService({
          tipoCurso: this.tipoCurso,
          idCurso: this.idCurso,
          idActividad: this.idActividad,
          tipoActividad: this.tipoActividad,
          data,
        });
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            criterio: serverResponse.criterio,
            puntosActividad: serverResponse.puntosActividad,
          };
          this.$emit("criterioModificado", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
