import { getServerRequest, putServerRequest, deleteServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function calificacionAlumnoActividadService(idCurso, idActividad) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/calificacionAlumno/${idCurso}/${idActividad}`;
    return await getServerRequest(url, config);
}

export const uploadDocumentService = async ({ form, uploadHandler, idCurso, idActividad }) => {
    const token = store.getters.sessionToken;
    const config = {
        headers: { token, 'Content-Type': 'multipart/form-data' },
        ...uploadHandler
    };
    const url = `${httpURL}/curso/campus/actividad/subirDocumento/${idCurso}/${idActividad}`;
    return await putServerRequest(url, form, config);
}

export const removeDocumentService = async ({ idDocumento, idCurso, idActividad }) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/documento/${idCurso}/${idActividad}/${idDocumento}`;
    return await deleteServerRequest(url, config);
}