import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,{attrs:{"rounded":"lg"}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.regresarCurso()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.nombreModulo)+" "),_c(VSpacer),(!_vm.vistaSeguimiento && _vm.permisoEscritura)?_c('actividadMenuComponent',{attrs:{"idActividad":_vm.idActividad,"idCurso":_vm.idCurso,"actividad":_vm.actividad,"idModulo":_vm.idModulo,"tipoCurso":_vm.tipoCurso,"disabled":_vm.loading},on:{"nombreModificado":_vm.nombreModificado,"eliminarActividad":_vm.regresarCurso}}):_vm._e()],1),(_vm.loading)?_c('actividadLoadingComponent'):_c('contenidoActividadComponent',{attrs:{"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"actividad":_vm.actividad,"equipos":_vm.equipos,"tipoModulo":_vm.tipoModulo,"tipoVista":_vm.tipoVista,"actividadPrevia":_vm.actividadPrevia,"actividadSiguiente":_vm.actividadSiguiente,"permisoEscritura":_vm.permisoEscritura,"cursoArchivado":_vm.cursoArchivado},on:{"cambioExtemporaneo":_vm.cambioExtemporaneo,"cambioFormarEquipos":_vm.cambioFormarEquipos,"criterioModificado":_vm.criterioModificado,"ponderacionModificada":_vm.ponderacionModificada,"tipoModificado":_vm.tipoModificado,"entregaModificada":_vm.entregaModificada,"instrumentoModificado":_vm.instrumentoModificado,"formatoArchivoModificado":_vm.formatoArchivoModificado,"cambioFechaEntrega":_vm.cambioFechaEntrega,"equipoAgregado":_vm.equipoAgregado,"equipoActividadEliminado":_vm.equipoActividadEliminado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }