<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="danger" dark dense flat class="text-h6"
        >Eliminar actividad</v-toolbar
      >

      <v-container grid-list-md>
        <b>¿Está seguro de elimiar la actividad?</b>
        <br />
        {{ actividad.nombre }} <br /><br>
        <i>Esta acción no podrá ser revertida.</i>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="cerrarForm()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            dark
            small
            :loading="loading"
            @click="eliminarActividad()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import { eliminarActividadService } from "./actividad.service";

export default {
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idModulo: String,
    actividad: Object,
  },
  data: () => ({
    loading: false,
  }),
  created() {},
  methods: {
    async eliminarActividad() {
      try {
        this.loading = true;
        const data = {tipoCurso: this.tipoCurso, idCurso: this.idCurso, idActividad: this.actividad._id}
        const serverResponse = await eliminarActividadService(data);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("eliminarActividad", this.actividad._id);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>