<template>
  <v-dialog v-model="mostrar" width="400" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark>
        <strong v-if="!editar"> Crear equipo</strong>
        <strong v-else> Modificar equipo</strong>
      </v-toolbar>
      <v-container>
        <v-text-field
          label="Nombre"
          v-model="nombre"
          dense
          outlined
        ></v-text-field>

        <v-card flat class="scroll-card">
          <v-list>
            <template v-for="(alumno, index) in alumnosLocal">
              <v-list-item
                :key="`alumnoDisponible-${index}`"
                two-line
                :disabled="alumno.disabled"
              >
                <v-list-item-action class="check-list">
                  <v-checkbox
                    style="padding-top: 0px"
                    v-model="selected"
                    :value="alumno._id"
                    :disabled="loading || alumno.disabled || alumno.admin"
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-avatar class="avatar-list">
                  <v-avatar color="primary" size="30">
                    <v-icon color="white" v-if="!alumno.avatar"
                      >mdi-account</v-icon
                    >
                    <img
                      v-else
                      :src="`${imageURL}/avatar/${alumno.avatar}`"
                      alt="UserImage"
                    />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ alumno.nombreCompleto }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="!alumno.equipo"> Disponible </span>
                    <span v-else>{{ alumno.equipo.nombre }} </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon v-if="alumno.admin">
                  <v-icon small color="yellow darken-2">mdi-star</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider :key="`div-list-${index}`"></v-divider>
            </template>
          </v-list>
        </v-card>
      </v-container>

      <v-card-actions>
        <v-btn
          v-if="false"
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="selectRandomUsers()"
          >Aleatorio</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          color="primary"
          :loading="loading"
          :class="{ 'disable-btn': selected.length == 0 }"
          @click="guardarEquipo()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

import {
  crearEquipoActividadService,
  modificarEquipoActividadService,
} from "./equipos.service";
export default {
  name: "equipoForm",
  props: {
    mostrar: Boolean,
    idCurso: String,
    idActividad: String,
    tipoActividad: Number,
    entregaActividad: Number,
    alumnos: Array,
    equipos: Array,
    idEquipo: String || null,
    usuarioCurso: { type: String, default: null }
  },

  computed: {
    ...mapGetters(["app", "imageURL", "sesionCursoAlumno"]),
    editar() {
      return this.idEquipo ? true : false;
    },
    cursoAlumno() {
      return this.usuarioCurso ? true : false;
    }
  },
  watch: {
    selected(selectedUsers, prevUsers) {
      if (selectedUsers.length === this.maxAlumnos)
        this.disableUnselectedUsers();
      if (
        prevUsers.length === this.maxAlumnos &&
        prevUsers.length != selectedUsers.length
      )
        this.enableUnselectedUsers();
    },
  },

  data: () => ({
    loading: false,
    nombre: "",
    integrantes: [],
    selected: [],
    maxAlumnos: 1,
    alumnosLocal: {},
    alumnosDisponibles: [],
  }),

  mounted() {
    // La siguiente instrucción se utiliza para cuando un alumno puede formar equipos (asignandole el admin)
    if (this.cursoAlumno) this.selected.push(this.usuarioCurso);
    if (this.editar) {
      const equipo = this.equipos.find((x) => x._id == this.idEquipo);
      this.nombre = equipo.nombre;
      this.selected = equipo.integrantes.map((x) => {return x.usuario;});
    } else this.nombre = `Equipo ${this.equipos.length + 1}`;
    this.maxAlumnos = this.entregaActividad;
    this.setAlumnosLocal();
    this.setAlumnosDisponibles();
  },
  methods: {
    setAlumnosLocal() {
      this.alumnos.forEach((alumno) => {
        // Si el alumno puede formar equipos y selecciona la opción de crear uno nuevo
        // se le asigna el roll de admin y se le deshabilita la opción del checkbox
        if(this.cursoAlumno && alumno._id === this.usuarioCurso) {
          this.alumnosLocal[alumno._id] = {
            ...alumno,
            equipo: null,
            disabled: true,
            admin: true,
          };
        } else {
          this.alumnosLocal[alumno._id] = {
            ...alumno,
            equipo: null,
            disabled: false,
            admin: false,
          };
        }
      });

      for (const equipo of this.equipos)
        for (const integrante of equipo.integrantes) {
          this.alumnosLocal[integrante.usuario].equipo = {
            _id: equipo._id,
            nombre: equipo.nombre,
          };
          this.alumnosLocal[integrante.usuario].admin = integrante.admin;
          this.alumnosLocal[integrante.usuario].disabled = this.editar
            ? equipo._id !== this.idEquipo || integrante.usuario === this.usuarioCurso
            : true;
        }
    },
    setAlumnosDisponibles() {
      const alumnos = _.values(this.alumnosLocal);
      alumnos.forEach((alumno) => {
        if (!alumno.equipo && alumno._id != this.usuarioCurso) this.alumnosDisponibles.push(alumno);
        else if (this.editar && alumno.equipo._id == this.idEquipo && alumno._id != this.usuarioCurso)
          this.alumnosDisponibles.push(alumno);
      });
      return this.alumnosDisponibles;
    },
    disableUnselectedUsers() {
      for (const key of _.keys(this.alumnosLocal))
        this.alumnosLocal[key].disabled = true;

      for (const idAlumno of this.selected)
        this.alumnosLocal[idAlumno].disabled =
          this.alumnosLocal[idAlumno].admin;
    },
    enableUnselectedUsers() {
      for (const key of _.keys(this.alumnosLocal))
        if (!this.alumnosLocal[key].equipo)
          this.alumnosLocal[key].disabled = false;
        else if (
          !this.alumnosLocal[key].admin &&
          this.alumnosLocal[key].equipo._id == this.idEquipo
        )
          this.alumnosLocal[key].disabled = false;
    },
    selectRandomUsers() {
      this.selected = [];
      if (this.alumnosDisponibles.length > this.maxAlumnos) {
        if (this.editar) {
          const adminEquipo = _.values(this.alumnosLocal).find(
            (x) => x.admin && x.equipo._id == this.idEquipo
          );
          this.selected.push(adminEquipo._id);
        }
        do {
          let position = Math.floor(Math.random() * this.alumnosDisponibles.length);

          if (!this.selected.find((x) => x == this.alumnosDisponibles[position]._id))
            this.selected.push(this.alumnosDisponibles[position]._id);

        } while (this.selected.length < this.maxAlumnos);
      } else 
        this.selected = this.alumnosDisponibles.map((x) => x._id);
      this.disableUnselectedUsers();
    },
    async guardarEquipo() {
      try {
        this.loading = true;
        let serverResponse = null;
        if (!this.editar) {
          serverResponse = await crearEquipoActividadService(
            this.idCurso,
            this.idActividad,
            this.nombre,
            this.selected
          );
        } else {
          serverResponse = await modificarEquipoActividadService(
            this.idCurso,
            this.idActividad,
            this.idEquipo,
            this.nombre,
            this.selected
          );
        }
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.editar
            ? this.$emit("equipoModificado", serverResponse.equipoModificado)
            : this.$emit("equipoAgregado", serverResponse.nuevoEquipo);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style scoped>
.scroll-card {
  max-height: 350px;
  width: 100%;
  overflow: scroll;
}

.check-list {
  margin-right: 5px !important;
  vertical-align: middle;
}

.avatar-list {
  margin-right: 5px !important;
  vertical-align: middle;
}
</style>
