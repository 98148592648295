<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dense flat dark
        ><strong>Modificar nombre</strong></v-toolbar
      >
      <v-container>
        <v-text-field
          dense
          outlined
          v-model="nombre"
          label="Nombre"
          :disabled="loading"
          :error-messages="nombreErrors"
          @input="$v.nombre.$touch()"
          @blur="$v.nombre.$touch()"
        ></v-text-field>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          color="primary"
          outlined
          small
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          :loading="loading"
          :class="{ 'disable-btn': nombreErrors.length > 0 || !nombre }"
          color="primary"
          small
          @click="modificarNombre()"
          >Modificar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { modificarNombreActividadService } from "./actividad.service";

export default {
  name: "agregarCursoCampus",
  props: {
    mostrar: Boolean,
    nombreAnterior: String,
    idActividad: { type: String, default: null },
  },
  mixins: [validationMixin],
  computed: {
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Campo requerido.");
      !this.$v.nombre.minLength && errors.push("Mínimo 5 caracteres.");
      return errors;
    },
  },
  validations: {
    nombre: { required, minLength: minLength(5) },
  },
  data: () => ({
    loading: false,
    nombre: null,
  }),
  created(){
    this.nombre = this.nombreAnterior;
  },
  methods: {
    async modificarNombre(){
      try {
        const tipoCurso = this.$route.params.tipoCurso
        const idCurso = this.$route.params.idCurso;

        this.loading = true;
        const data = { nombre: this.nombre };
        const serverResponse = await modificarNombreActividadService(idCurso, tipoCurso, this.idActividad, data);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("nombreModificado", serverResponse.nombre);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
