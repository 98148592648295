import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-bold",attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Modificar Entrega")])],1),_c(VContainer,[(!_vm.totalEquipos && [1, 3].find(function (n) { return n == _vm.tipo; }))?_c(VAlert,{attrs:{"color":"orange lighten-1","dense":"","dark":""}},[_vm._v(" Al modificar el tipo de entrega se eliminaran las calificaciones registradas. ")]):_vm._e(),(!_vm.totalEquipos && _vm.tipo == 2)?_c(VAlert,{attrs:{"color":"orange lighten-1","dense":"","dark":""}},[_vm._v(" Al modificar el tipo de entrega se eliminaran las calificaciones registradas junto con sus respectivas entregas. ")]):_vm._e(),_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Entrega:","disabled":_vm.loading || _vm.loading,"items":_vm.equipos,"loading":_vm.loading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VListItem,{attrs:{"dense":""}},[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)]}}]),model:{value:(_vm.entregaSelect),callback:function ($$v) {_vm.entregaSelect=$$v},expression:"entregaSelect"}}),(_vm.totalEquipos > 0 && _vm.entregaSelect == 1)?_c(VAlert,{attrs:{"type":"warning"}},[_c('strong',[_vm._v(" Esta actividad ya cuenta con equipos, al cambiarlo a individual se eliminaran junto con sus respectivas entregas y calificaciones. ")])]):_vm._e()],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v(" Cancelar ")]),_c(VBtn,{class:{ 'disable-btn': !_vm.esValido },attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.modificarEntrega()}}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }