<template>
  <div>
    <v-list-item
      dense
      @mouseover="permisoEscritura && !vistaSeguimiento ? (modificarIcon = true) : null"
      @mouseout="permisoEscritura ? (modificarIcon = false) : null"
    >
      <v-list-item-icon>
        <v-btn
          v-if="modificarIcon && !vistaSeguimiento"
          fab
          x-small
          color="primary"
          @click="modificarEntrega = true"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else color="primary">{{
          entrega == 1 ? "mdi-account" : "mdi-account-multiple"
        }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Entrega</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="entrega == 1">Individual</span>
          <span v-else>Equipos de hasta {{ entrega }} integrantes</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <modificarEntregaActividad
      v-if="modificarEntrega"
      :mostrar="modificarEntrega"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipo="tipo"
      :entrega="entrega"
      :totalEquipos="equipos.length"
      @cancelar="modificarEntrega = false"
      @entregaModificada="entregaModificada"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modificarEntregaActividad from "./modificarEntregaActividad.vue";
export default {
  name: "entregaActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipo: Number,
    entrega: Number,
    equipos: Array,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: { modificarEntregaActividad },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
  },
  data() {
    return {
      modificarIcon: false,
      modificarEntrega: false,
    };
  },
  methods: {
    entregaModificada(data) {
      this.modificarEntrega = false;
      this.$emit("entregaModificada", data);
    },
  },
};
</script>
