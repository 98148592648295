<template>
  <v-dialog v-model="mostrar" :max-width="600" persistent>
    <v-card v-if="!mostrarEliminar">
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title v-if="this.instrumento">Modificar Instrumento</v-toolbar-title>
        <v-toolbar-title v-else>Agregar Instrumento</v-toolbar-title>
      </v-toolbar>

      <v-container>
        <v-alert color="orange lighten-1 text-center" dense dark>
          Al modificar o eliminar el instrumento también se eliminaran las calificaciones registradas.
        </v-alert>
        <v-card-subtitle v-if="mensajeCompartidos" class="pt-0 text-center" style="color: #1A237E;">
          Las áreas e instrumentos que se muestran son los compartidos por la {{ mensajeCompartidos }}.
        </v-card-subtitle>

        <v-select
          :items="tiposInstrumentos"
          v-model="tipoSeleccionado"
          item-text="nombre"
          item-value="tipo"
          label="Tipo:"
          outlined
          hide-details
          dense
          @change="cambiarTipo"
        ></v-select>
      </v-container>
      <v-container>
        <v-select
          :items="areasLocales"
          v-model="areaSeleccionadaLocal"
          item-text="nombre"
          item-value="_id"
          :label="areasLocales ? 'Área:' : 'No hay áreas capturadas'"
          outlined
          hide-details
          no-data-text="No hay áreas capturadas"
          dense
          :disabled="loading || !areasLocales"
          @change="seleccionarInstrumentos"
        ></v-select>
      </v-container>
      <v-container>
        <v-select
          :items="instrumentosLocales"
          v-model="instrumentoSeleccionadoLocal"
          item-text="nombre"
          item-value="_id"
          :label="instrumentosLocales ? 'Instrumento:' : 'No hay instrumentos capturados'"
          outlined
          hide-details
          no-data-text="No hay instrumentos capturados"
          dense
          :disabled="loading || !areasLocales"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.nombre }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.tipo">{{
                item.tipo
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-btn
          color="danger"
          outlined
          small
          :disabled="loading || !instrumento"
          @click="mostrarEliminar = true"
        >
          Eliminar
        </v-btn>        
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !esValido }"
          @click="modificarInstrumento()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
 
      </v-card>

      <v-dialog v-else v-model="mostrarEliminar" persistent max-width="500" hide-overlay transition="scale-transition">
        <v-card>
          <v-toolbar dense color="danger" dark flat class="text-h6">Eliminar instrumento</v-toolbar>
          <v-container grid-list-md>
            <p><b>¿Está seguro de eliminar el instrumento de la actividad?</b> <br></p>
            <p>En caso de haber calificaciones capturadas, estas serán eliminadas.</p>
            <i>Esta acción no podrá ser revertida</i>
          </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" 
                small 
                outlined 
                :disabled="loading" 
                @click="mostrarEliminar = false">Cancelar</v-btn>
                <v-btn color="danger" 
                small
                dark
                :loading="loading" 
                @click="eliminarInstrumento()">Eliminar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
  </v-dialog>
</template>

<script>

import { modificarInstrumentoService, obtenerInstrumentosService } from "../actividad.service";
export default {
  name: "modificarInstrumentoActividad",
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    instrumento: [Object, null],
    tipoInstrumento: String,
    permisoEscritura: { type: Boolean, default: false },
  },
  watch: {
    tipoSeleccionado(value) {
      if(value === 'listaCotejo') this.areasLocales = this.areasListasCotejo;
      else this.areasLocales = this.areasRubricas;
    },
    areasLocales() {
      if(this.instrumento && this.tipoInstrumento == this.tipoSeleccionado) this.buscarInstrumentoSeleccionado()
      else if (this.areasLocales.length > 0) { 
        this.areaSeleccionadaLocal = this.areasLocales[0]._id;
        this.seleccionarInstrumentos()
      }
    },
  },
  computed: {
    esValido() {
      if (this.instrumentoSeleccionadoLocal) 
        if(this.instrumento){
          if(this.instrumentoSeleccionadoLocal != this.instrumento._id) return true
          else return false
        } else return true
      else return false
    },
  },
  data() {
    return {
      loading: false,
      // Tipo
      tiposInstrumentos: [
        { nombre: 'Listas cotejo', tipo: 'listaCotejo' },
        { nombre: 'Rubricas', tipo: 'rubrica' }],
      tipoSeleccionado: null,
      // Áreas
      areasLocales: [],
      areaSeleccionadaLocal: null,
      areasListasCotejo: [],
      areasRubricas: [],
      // Instrumentos
      instrumentosLocales: [],
      instrumentoSeleccionadoLocal: null,
      listasCotejo: [],
      rubricas: [],
      mostrarEliminar: false,
      mensajeCompartidos: null,
    };
  },
  async created() {
    await this.cargarInstrumentos();
    if (!this.instrumento) {
      this.tipoSeleccionado = this.tiposInstrumentos[0].tipo;
    } else {
      this.tipoSeleccionado = this.tipoInstrumento;
    }
  },
  methods: {
    async cargarInstrumentos() {
      try {
        this.loading = true;
        const serverResponse = await obtenerInstrumentosService();
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.areasListasCotejo = serverResponse.respuesta.areasListas;
          this.areasRubricas = serverResponse.respuesta.areasRubricas;
          this.listasCotejo = serverResponse.respuesta.listasCotejo;
          this.rubricas = serverResponse.respuesta.rubricas;
          this.mensajeCompartidos = serverResponse.respuesta.compartidos;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async modificarInstrumento() {
      try {
        this.loading = true;
        const data = { tipoInstrumento: this.tipoSeleccionado, instrumento: this.instrumentoSeleccionadoLocal };
        const serverResponse = await modificarInstrumentoService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad,
          data
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            instrumento: serverResponse.instrumento,
            tipoInstrumento: serverResponse.tipoInstrumento
          };
          this.$emit("instrumentoModificado", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    cambiarTipo(tipo){
      if(tipo === 'listaCotejo') this.areasLocales = this.areasListasCotejo;
      else this.areasLocales = this.areasRubricas;
    },
    seleccionarInstrumentos(){
      this.instrumentosLocales = [];
      if (this.tipoSeleccionado === 'listaCotejo') 
        this.instrumentosLocales = this.listasCotejo.filter((lista) => lista.instrumentoArea[0]._id == this.areaSeleccionadaLocal)
      else 
        this.instrumentosLocales = this.rubricas.filter((rubrica) => rubrica.instrumentoArea[0]._id == this.areaSeleccionadaLocal)

      if(this.instrumentosLocales.length > 0){
        if(this.instrumento && this.tipoSeleccionado == this.tipoInstrumento) {
          const index = this.instrumentosLocales.findIndex((x) => x._id === this.instrumento._id);
          if (index >= 0) this.instrumentoSeleccionadoLocal = this.instrumentosLocales[index]._id;
          else this.instrumentoSeleccionadoLocal = this.instrumentosLocales[0]._id;
        } else this.instrumentoSeleccionadoLocal = this.instrumentosLocales[0]._id;
      } else {
          this.instrumentoSeleccionadoLocal = [];
          this.instrumentoSeleccionadoLocal = null;
      }
    },
    buscarInstrumentoSeleccionado() {
      // ESTA INSTRUCCIÓN SOLO SE EJECUTA SI EL INSTRUMENTO YA VIENE DEFINIDO
      let instrumentoSeleccionado = {};
  
      if (this.tipoSeleccionado === 'listaCotejo')
        instrumentoSeleccionado = this.listasCotejo.find((lista) => {return lista._id == this.instrumento._id});
      else 
        instrumentoSeleccionado = this.rubricas.find((rubrica) => {return rubrica._id == this.instrumento._id});


      if (instrumentoSeleccionado) {
        const indexArea = this.areasLocales.findIndex(area => area._id === instrumentoSeleccionado.instrumentoArea[0]._id);
        this.areaSeleccionadaLocal = this.areasLocales[indexArea]._id;
      } else {
        if (this.areasLocales.length) this.areaSeleccionadaLocal = this.areasLocales[0]._id;
      }

      this.seleccionarInstrumentos();
    },
    async eliminarInstrumento() {
      try {
        this.loading = true;
        const data = { tipoInstrumento: null, instrumento: null };
        const serverResponse = await modificarInstrumentoService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad,
          data
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            instrumento: serverResponse.instrumento,
            tipoInstrumento: serverResponse.tipoInstrumento
          };
          this.$emit("instrumentoModificado", emitData);
          this.mostrarEliminar = false;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>