<template>
  <div class="text-center">
    <v-menu offset-x left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on" :disabled="disabled">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="habilitarEdicion()">
          <v-list-item-title>Modificar actividad</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
        </v-list-item>
        <v-list-item @click="eliminarActividad = true">
          <v-list-item-title>
            <font color="#D32F2F"> Eliminar actividad </font>
          </v-list-item-title>
          <v-list-item-icon
            ><v-icon color="red darken-2" small
              >mdi-trash-can-outline</v-icon
            ></v-list-item-icon
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <eliminarActividadComponent
      v-if="eliminarActividad"
      :mostrar="eliminarActividad"
      :idCurso="idCurso"
      :actividad="actividad"
      :idModulo="idModulo"
      :tipoCurso="tipoCurso"
      @cancelar="eliminarActividad = false"
      @eliminarActividad="eliminar"
    />

    <modificarActividadComponent
      v-if="modificarActividad"
      :mostrar="modificarActividad"
      :idCurso="idCurso"
      :nombreAnterior="editarActividad.nombre"
      :idActividad="editarActividad._id"
      @cancelar="modificarActividad = false"
      @nombreModificado="actividadModificada"
    />
  </div>
</template>

<script>
import eliminarActividadComponent from "./eliminarActividad.vue"
import modificarActividadComponent from "./modificarActividad.vue"
export default {
  name: "actividadMenu",
  components: { modificarActividadComponent, eliminarActividadComponent },
  props: {
    idActividad: String,
    idCurso: String,
    actividad: Object,
    idModulo: String,
    tipoCurso: String,
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      loading: true,
      modificarActividad: false,
      editarActividad: null,
      eliminarActividad: false,
    };
  },
  methods: {
    habilitarEdicion() {
      this.editarActividad = { ...this.actividad };
      this.modificarActividad = true;
    },
    actividadModificada(nombre) {
      this.modificarActividad = false;
      this.$emit("nombreModificado", nombre);
    },
    eliminar(idActividad){
      this.$emit("eliminarActividad", idActividad);
    },
  },
};
</script>
