<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title>Modificar Formato de archivo</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-select
          v-model="tipoSelect"
          :items="tiposFormato"
          item-text="text"
          item-value="values"
          label="Formato archivo:"
          outlined
          dense
          :disabled="loading"
          :loading="loading"
          @change="selectedType()"
        >
          <template v-slot:selection="{ item }">
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item dense v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !esValido }"
          @click="modificarFormato()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { modificarFormatoArchivoService } from "../actividad.service";
export default {
  name: "modificarFormatoArchivoActividad",
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    permisoEscritura: { type: Boolean, default: false },
    formatoArchivo: { type: Array, default: null}
  },
  computed: {
    esValido() {

      if (this.tipoSelect) {
        if (!this.formatoArchivo) {
          if(this.tipoSelect.values != null ) return true;
        } else {
          if(this.tipoSelect.values == null) return true;
          if(this.tipoSelect.values.toString() != this.formatoArchivo.toString()) return true;
        }
      }

      return false;
    },
    tiposFormato() {
      const tipos = [
        { values: null, text: "Todos", icon: 'mdi-file-document-remove' },
        { values: ['pdf'], text: "PDF", icon: 'mdi-file' },
        { values: ['docx', 'doc'], text: "Word", icon: 'mdi-file-word' },
        { values: ['xlsx', 'xls'], text: "Excel", icon: 'mdi-file-excel' },
        { values: ['pptx', 'ppt'], text: "PowerPoint", icon: 'mdi-file-powerpoint' },
        { values: ['jpg', 'png', 'jpeg'], text: "Imagen", icon: 'mdi-file-image' },
      ];
      return tipos;
    },
  },
  data() {
    return {
      loading: false,
      tipoSelect: null,
      tipos: [],
    };
  },
  mounted() {
    this.setTipo();
  },
  methods: {
    setTipo() {
      if (this.formatoArchivo == null) this.tipoSelect = this.tiposFormato[0];
      else {
        const type = this.tiposFormato.find(({values}) => values == this.formatoArchivo.toString() );
        this.tipoSelect = type;
      }
    },
    selectedType() {
      if (this.tipoSelect == null) this.tipoSelect = this.tiposFormato[0];
      else {
        const type = this.tiposFormato.find(({values}) => values == this.tipoSelect.toString() );
        this.tipoSelect = type;
      }
    },
    async modificarFormato() {
      try {
        this.loading = true;
        const data = { formatoArchivo: this.tipoSelect.values };

        const serverResponse = await modificarFormatoArchivoService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad,
          data
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = { formatoArchivo: serverResponse.formatoArchivo };
          this.$emit("formatoArchivoModificado", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
