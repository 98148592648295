<template>
  <v-dialog v-model="mostrar" width="450" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark>
        <strong>Importar equipos</strong>
      </v-toolbar>

      <v-container v-if="loading && !loadingImportar">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-container>

      <v-container v-else>
        <v-alert color="orange lighten-1" dense dark style="text-align: center;" v-if="listadoActividades.length == 0 ||  actividadSeleccionada.equipos.length == 0 || actividadSeleccionada.entrega > entregaActividad">
          {{ mensaje() }}
        </v-alert>

        <v-select
          :items="listadoActividades"
          v-model="actividadSeleccionada"
          item-text="nombre"
          item-value="idActividad"
          :label="listadoActividades.length == 0 ? 'No existen actividades con entrega de tipo equipo' : 'Actividad'"
          outlined
          hide-details
          dense
          @change="seleccionarActividad"
        ></v-select>

        <v-card outlined elevation="0" class="mt-2" flat>
          <v-list>
            <v-list-item v-for="(equipo, i) in actividadSeleccionada.equipos" :key="i" >
              <v-list-item-content>
                    <v-list-item-title>{{ i + 1 }}. {{ equipo.nombre }}</v-list-item-title>
                    <v-list-item-subtitle>- Integrantes: {{ equipo.integrantes.length }}</v-list-item-subtitle>
                <v-divider class="mt-2"></v-divider>
              </v-list-item-content>
            </v-list-item>
            
          </v-list>
      </v-card>


      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading || loadingImportar"
          @click="$emit('cancelar')"
        >Cancelar
        </v-btn>
        <v-btn
          small
          color="primary"
          :loading="loading || loadingImportar"
          :class="{ 'disable-btn': listadoActividades.length == 0 ||  actividadSeleccionada.equipos.length == 0 || actividadSeleccionada.entrega > entregaActividad}"
          @click="importarEquipos()"
        >Importar
        </v-btn>
      </v-card-actions>



    </v-card>
  </v-dialog>
</template>

<script>
import { obtenerActividadesService } from "../../modulos/actividades/actividades.service";
import { importarEquiposActividadService } from "./equipos.service";

export default {
  name: "equipoImport",
  props: {
    mostrar: { type: Boolean, default: false },
    idModulo: { type: String || null, default: null },
    idActividad: { type: String || null, default: null },
    entregaActividad: { type: Number, default: null }
  },
  computed: {
    listadoActividades() {
      const actividadesResponse = [];
      for (const actividad of this.actividades) {
        if(actividad._id != this.idActividad && actividad.entrega > 1) {
          actividadesResponse.push({
            idActividad: actividad._id,
            nombre: actividad.nombre,
            entrega: actividad.entrega,
            equipos: actividad.equipos,
          });
        }
      }
      return actividadesResponse;
    }
  },
  watch: {
    listadoActividades() {
      this.seleccionarActividad();
    }
  },
  created() {
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.cargarActividades();
  },
  data: () => ({
    loading: false,
    loadingImportar: false,
    idCurso: null,
    tipoCurso: null,
    actividades: [],
    actividadSeleccionada:{}
  }),
  methods: {
    async cargarActividades() {
      try {
        this.loading = true;
        const serverResponse = await obtenerActividadesService(
          this.tipoCurso,
          this.idCurso,
          this.idModulo
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.actividades = serverResponse.actividades;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    seleccionarActividad(value) {
      if(this.listadoActividades.length != 0) {
        if(!value) {
          this.actividadSeleccionada = this.listadoActividades[0];
        } else {
          const index = this.listadoActividades.findIndex((actividad) => actividad.idActividad == value);
          this.actividadSeleccionada = this.listadoActividades[index];
        }
      }
    },
    mensaje() {
      if(this.listadoActividades.length == 0)
        return 'No existen actividades con entrega de tipo equipo';
      if(this.actividadSeleccionada.equipos.length == 0)
        return 'La actividad no cuenta con equipos para importar'
      if(this.actividadSeleccionada.entrega > this.entregaActividad)
        return 'La configuración de equipos no es compatible'
    },
    async importarEquipos() {
      try {
        this.loadingImportar = true;
        const data = { idActividadExp: this.actividadSeleccionada.idActividad }
        const serverResponse = await importarEquiposActividadService(
          this.idCurso,
          this.idActividad,
          data
        );
        this.loadingImportar = false;
        if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("equiposImportados", serverResponse.equipos);
        }
      } catch (error) {
        this.loadingImportar = false;
        this.$appErrorMessage();
      }
    }
  }
  
}
</script>


