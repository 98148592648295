<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title>Modificar disponibilidad</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-list-item>
            <v-list-item-action>
              <switchMini
                :active="fechaAbierta"
                @activate="fechaAbierta = !fechaAbierta"
                @deactivate="fechaAbierta = !fechaAbierta"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Fecha de entrega abierta</v-list-item-title>
              <v-list-item-subtitle
                >Los alumnos pueden entregar en cualquier
                momento</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-row>

        <v-row no-gutters style="padding-top: 30px">
          <v-col cols="6" style="padding-right: 10px">
            <!-- FECHA DE INICIO -->
            <v-menu
              v-model="fechaInicioPicker"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :value="fechaInicioTexto"
                  label="Fecha inicio:"
                  readonly
                  outlined
                  dense
                  :disabled="loading || fechaAbierta"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaInicio"
                locale="es"
                @change="fechaInicioPicker = false"
                :max="fechaFin"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" style="padding-left: 10px">
            <!-- HORA DE INICIO -->
            <v-menu
              v-model="horaInicioPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="horaInicio"
                  label="Hora"
                  readonly
                  v-on="on"
                  outlined
                  dense
                  :disabled="loading || fechaAbierta"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="horaInicioPicker"
                v-model="horaInicio"
                format="24hr"
                @change="horaInicioPicker = false"
                full-width
              ></v-time-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" style="padding-right: 10px">
            <!-- FECHA DE FIN -->
            <v-menu
              v-model="fechaFinPicker"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :value="fechaFinTexto"
                  label="Fecha fin:"
                  readonly
                  outlined
                  dense
                  :disabled="loading || fechaAbierta"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaFin"
                locale="es"
                @change="fechaFinPicker = false"
                :min="fechaInicio"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" style="padding-left: 10px">
            <!-- HORA DE FIN -->
            <v-menu
              v-model="horaFinPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="horaFin"
                  label="Hora"
                  readonly
                  v-on="on"
                  outlined
                  dense
                  :disabled="loading || fechaAbierta"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="horaFinPicker"
                v-model="horaFin"
                format="24hr"
                @change="horaFinPicker = false"
                full-width
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" v-if="!rangoValido">
            <p style="color: #aa0000">
              La fecha y hora de inicio debe ser mayor a la de finalización
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !rangoValido }"
          @click="actualizarFecha()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import switchMini from "../../../widgets/switchMini.vue";
import { modificarFechaEntrega } from "../actividad.service";

export default {
  name: "modificarDisponibilidadActividad",
  props: {
    mostrar: Boolean,
    idCurso: String,
    idActividad: String,
    disponible: Object,
  },
  components: {
    switchMini,
  },
  computed: {
    fechaInicioTexto() {
      var dt = DateTime.fromISO(this.fechaInicio);
      return dt.toLocaleString(DateTime.DATE_FULL);
    },
    fechaFinTexto() {
      var dt = DateTime.fromISO(this.fechaFin);
      return dt.toLocaleString(DateTime.DATE_FULL);
    },
    rangoValido() {
      if (this.fechaInicio == this.fechaFin) {
        var horaInicio = DateTime.fromISO(this.horaInicio);
        var horaFin = DateTime.fromISO(this.horaFin);
        return horaInicio < horaFin;
      }
      return true;
    },
  },
  data() {
    return {
      loading: false,
      fechaAbierta: true,
      fechaInicio: null,
      fechaInicioPicker: false,
      horaInicio: null,
      horaInicioPicker: false,
      fechaFin: null,
      fechaFinPicker: false,
      horaFin: null,
      horaFinPicker: false,
    };
  },
  created() {
    this.fechaAbierta = this.disponible.abierta;
    this.fechaInicio =
      DateTime.fromISO(this.disponible.inicio).toISODate() ||
      DateTime.now().toISODate();
    if (this.disponible.fin)
      this.fechaFin = DateTime.fromISO(this.disponible.fin).toISODate();
    else {
      let fechaInicio = DateTime.fromISO(this.fechaInicio);
      this.fechaFin = fechaInicio.plus({ months: 1 }).toISODate();
    }
    this.horaInicio = this.disponible.inicio
      ? DateTime.fromISO(this.disponible.inicio).toLocaleString(
          DateTime.TIME_24_SIMPLE
        )
      : "12:00";
    this.horaFin = this.disponible.fin
      ? DateTime.fromISO(this.disponible.fin).toLocaleString(
          DateTime.TIME_24_SIMPLE
        )
      : "12:00";
  },
  methods: {
    async actualizarFecha() {
      try {
        const senData = {
          abierta: this.fechaAbierta,
          inicio: DateTime.fromSQL(
            `${this.fechaInicio} ${this.horaInicio}:00`
          ).toISO(),
          fin: DateTime.fromSQL(`${this.fechaFin} ${this.horaFin}:00`).toISO(),
        };
        this.loading = true;
        const serverResponse = await modificarFechaEntrega(
          this.idCurso,
          this.idActividad,
          senData
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("cambioFechaEntrega", serverResponse.disponible);
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
