<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title>Modificar Tipo</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-alert v-if="tipo == 1" color="orange lighten-1" dense dark>
          Al modificar el tipo también se eliminaran las calificaciones registradas.
        </v-alert>
        <v-alert v-if="tipo == 2" color="orange lighten-1" dense dark>
          Al modificar el tipo también se eliminaran las calificaciones registradas junto con sus respectivas entregas.
        </v-alert>
        <v-select
          v-model="tipoSelect"
          outlined
          dense
          label="Tipo:"
          :disabled="loading || loading"
          :items="tipos"
          :loading="loading"
        >
          <template v-slot:selection="{ item }">
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item dense v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !esValido }"
          @click="modificarTipo()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { modificarTipoService } from "../actividad.service";
export default {
  name: "modificarTipoActividad",
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipo: Number,
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters([
        "actividadDirectaIcon",
        "actividadArchivoIcon",
        "actividadForoIcon",
        "actividadEncuestaIcon",
    ]),
    esValido() {
      return this.tipoSelect != this.tipo;
    },
  },
  data() {
    return {
      loading: false,
      tipoSelect: null,
      tipos: [],
    };
  },
  mounted() {
    this.setTipos();
  },
  methods: {
      setTipos() {
      this.tipos = [
        { value: 1, text: "Calificación directa", icon: this.actividadDirectaIcon },
        { value: 2, text: "Subir archivo", icon: this.actividadArchivoIcon },
        { value: 3, text: "Foro", icon: this.actividadForoIcon },
        //{ value: 4, text: "Encuesta", icon: this.actividadEncuestaIcon },
      ];
      this.tipoSelect = this.tipo;
    },
    async modificarTipo() {
      try {
        this.loading = true;
        const data = { tipo: this.tipoSelect };
        const serverResponse = await modificarTipoService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad,
          data
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = { tipo: serverResponse.tipo };
          this.$emit("tipoModificado", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
