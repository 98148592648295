<template>
  <div>
    <v-list-item
      dense
      @mouseover="permisoEscritura && !vistaSeguimiento ? (modificarIcon = true) : null"
      @mouseout="permisoEscritura ? (modificarIcon = false) : null"
    >
      <v-list-item-icon>
        <v-btn v-if="modificarIcon && !vistaSeguimiento" fab x-small color="primary" @click="modificarFormato = true">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else color="primary">mdi-file-cog-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Tipo archivo</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="!formatoArchivoLocal">Todos</span>
          <span v-else>{{ formatoArchivoLocal.toString() }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    
    <modificarFormatoArchivoActividad
      v-if="modificarFormato"
      :mostrar="modificarFormato"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :formatoArchivo="formatoArchivoLocal"
      @cancelar="modificarFormato = false"
      @formatoArchivoModificado="formatoArchivoModificado"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modificarFormatoArchivoActividad from "./modificarFormatoArchivoActividad.vue";

export default {
  name: "formatoArchivoActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    permisoEscritura: { type: Boolean, default: false },
    formatoArchivo: { type: Array, default: null }
  },
  components: { modificarFormatoArchivoActividad },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
  },
  data() {
    return {
      modificarIcon: false,
      modificarFormato: false,
      formatoArchivoLocal: null,
    };
  },
  created() {
    this.formatoArchivoLocal = this.formatoArchivo;
  },
  methods: {
    formatoArchivoModificado(data) {
      this.modificarFormato = false;
      this.formatoArchivoLocal = data.formatoArchivo;
      this.$emit("formatoArchivoModificado", data);
    }
  },
};
</script>
