import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_c('strong',[_vm._v(" Detalle calificaciones")])]),_c(VContainer,_vm._l((_vm.calificacionesLocal),function(calificacion){return _c(VCard,{key:calificacion._id,attrs:{"outlined":""}},[_c(VListItem,[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"indigo","size":"36"}},[_c('span',{staticClass:"white--text text-strong"},[_vm._v(_vm._s(calificacion.calificacion))])])],1),_c(VListItemTitle,[_vm._v(_vm._s(calificacion.fecha))]),(calificacion.nombreArchivo)?_c(VListItemSubtitle,{staticStyle:{"text-align":"center"},attrs:{"max-width":"10px"}},[_vm._v(_vm._s(calificacion.nombreArchivo))]):_vm._e()],1),_c(VDivider,{staticClass:"mx-3"}),(calificacion.observaciones)?_c(VCardText,[_vm._v(" "+_vm._s(calificacion.observaciones)+" ")]):_vm._e()],1)}),1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.$emit('cerrar')}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }