<template>
  <div>
    <!-- No se cuenta con documento -->
    <v-list-item v-if="!documentoActual" dense>
      <v-list-item-content>
        <v-list-item-title>
          <i>No se ha entregado la actividad.</i>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- Se cuenta con documento -->
    <v-list-item v-else>
      <v-list-item-group>
        <!-- Se tiene archivo -->
        <v-list-item-content>
          <v-list-item-subtitle
            v-if="documentoActual.revision.observaciones"
            >{{ documentoActual.revision.observaciones }}</v-list-item-subtitle
          >
          <v-list-item-title>
            <!-- APARTADO -->
            <a
              href="javascript:void(0);"
              class="link"
              @click="mostrarArchivo(documentoActual._id, documentoActual.file)"
              >{{ documentoActual.file.name }}</a
            >
            <span class="document-size">{{
              documentoSize(documentoActual)
            }}</span>
          </v-list-item-title>
          <v-list-item-subtitle
            >{{ documentoFecha(documentoActual) }}
            <v-chip
              class="mx-1"
              x-small
              outlined
            >{{ documentoTipo }}</v-chip>
            <v-chip
              v-if="documentoExt(documentoActual)"
              x-small
              color="orange darken-1"
              dark
              >Extem.</v-chip
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-chip
              v-if="
                !documentoActual.revision.calificacion &&
                typeof documentoActual.revision.calificacion != 'number'
              "
              label
              x-small
              color="amber darken-1"
              text-color="grey darken-1"
              outlined
              ><v-icon left>mdi-pen</v-icon>No se ha calificado la
              actividad.</v-chip
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-chip
              v-if="documentoActual.revision.reenvio"
              label
              x-small
              color="orange darken-4"
              outlined
              ><v-icon left>mdi-alert</v-icon>Se ha solicitado reenvio</v-chip
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-chip
              v-if="
                !documentoActual.revision.reenvio &&
                typeof documentoActual.revision.calificacion == 'number'
              "
              label
              x-small
              color="primary"
              text-color="grey darken-1"
              outlined
              ><v-icon left>mdi-check</v-icon>Calificado</v-chip
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item-group>
    </v-list-item>

    <detallesCalificacionComponent
      v-if="verDetalles"
      :mostrar="verDetalles"
      :calificaciones="historialCalificaciones"
      @cerrar="verDetalles = false"
    />

    <v-card>
      <viewer
        v-if="verArchivo.mostrar"
        :mostrar="verArchivo.mostrar"
        :idRecurso="verArchivo.idRecurso"
        :nombreArchivo="verArchivo.filename"
        :url="verArchivo.url"
        :tipoArchivo="verArchivo.tipo"
        :permisos="verArchivo.permisos"
        @cerrar="verArchivo.mostrar = false"
      />
    </v-card>

    <!-- CALIFICACIÓN -->
    <agregarCalificacionArchivoComponent
      v-if="
        (agregarCalificacion && !instrumento) ||
        (previsualizarDocumento && !instrumento)
      "
      :mostrar="agregarCalificacion || previsualizarDocumento"
      :mostrarCalificar="agregarCalificacion"
      :mostrarDocumento="previsualizarDocumento"
      :editarCalificacion="editarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :disponible="disponible"
      :instrumento="instrumento"
      :alumnos="this.alumnos"
      :equipos="this.equipos"
      :idAlumno="idAlumno"
      :idEquipo="idEquipo"
      @cancelar="
        (agregarCalificacion = false),
          (editarCalificacion = false),
          (previsualizarDocumento = false)
      "
      @calificacionModificadaArchivo="calificacionModificadaArchivo"
    />

    <!-- EMPEZAR -->

    <agregarCalificacionArchivoListaCotejoComponent
      v-if="
        (agregarCalificacion && tipoInstrumento == 'listaCotejo') ||
        (previsualizarDocumento && tipoInstrumento == 'listaCotejo')
      "
      :mostrar="agregarCalificacion || previsualizarDocumento"
      :mostrarCalificar="agregarCalificacion"
      :mostrarDocumento="previsualizarDocumento"
      :editarCalificacion="editarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :disponible="disponible"
      :instrumento="instrumento"
      :alumnos="this.alumnos"
      :equipos="this.equipos"
      :idAlumno="idAlumno"
      :idEquipo="idEquipo"
      @cancelar="
        (agregarCalificacion = false),
          (editarCalificacion = false),
          (previsualizarDocumento = false)
      "
      @calificacionModificadaArchivo="calificacionModificadaArchivo"
    />

    <agregarCalificacionArchivoRubricaComponent
      v-if="
        (agregarCalificacion && tipoInstrumento == 'rubrica') ||
        (previsualizarDocumento && tipoInstrumento == 'rubrica')
      "
      :mostrar="agregarCalificacion || previsualizarDocumento"
      :mostrarCalificar="agregarCalificacion"
      :mostrarDocumento="previsualizarDocumento"
      :editarCalificacion="editarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :disponible="disponible"
      :instrumento="instrumento"
      :alumnos="this.alumnos"
      :equipos="this.equipos"
      :idAlumno="idAlumno"
      :idEquipo="idEquipo"
      @cancelar="
        (agregarCalificacion = false),
          (editarCalificacion = false),
          (previsualizarDocumento = false)
      "
      @calificacionModificadaArchivo="calificacionModificadaArchivo"
    />

    <noFile
      v-if="sinArchivo.mostrar"
      :mostrar="sinArchivo.mostrar"
      :nombreArchivo="sinArchivo.nombre"
      @cerrar="sinArchivo.mostrar = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import numeral from "numeral";

import agregarCalificacionArchivoComponent from "./calificacionEntregas/calArchivo/agregarCalificacionArchivo.vue";
import detallesCalificacionComponent from "./detallesCalificacion.vue";
import agregarCalificacionArchivoListaCotejoComponent from "./calificacionEntregas/calArchivo/agregarCalificacionArchivoListaCotejo.vue";
import agregarCalificacionArchivoRubricaComponent from "./calificacionEntregas/calArchivo/agregarCalificacionArchivoRubrica.vue";
import viewer from "../../../widgets/fileViewer.vue";
import { validaExistenciaService } from "../../../recursos/recursos.service";
import noFile from "../../../widgets/noFile.vue";
import { getTypeFormatByName } from '../../../../helpers/utilerias';

export default {
  name: "calArchivoItem",
  props: {
    idCurso: { type: String || null, default: null },
    idActividad: { type: String || null, default: null },
    disponible: { type: Object, default: () => {} },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    alumnos: { type: Array, default: () => [] },
    equipos: { type: Array, default: () => [] },
    idAlumno: { type: String || null, default: null },
    idEquipo: { type: String || null, default: null },
    permisoEscritura: { type: Boolean, default: false },
    nombre: String,
    alumno: { type: Object, default: () => {} },
    equipo: { type: Object, default: () => {} },
    documentos: { type: Array, default: () => [] },
  },
  components: {
    agregarCalificacionArchivoComponent,
    detallesCalificacionComponent,
    agregarCalificacionArchivoListaCotejoComponent,
    agregarCalificacionArchivoRubricaComponent,
    viewer,
    noFile,
  },
  computed: {
    ...mapGetters(["documentsURL", "vistaSeguimiento"]),
    documentoActual() {
      if (this.documentos.length != 0) return this.documentos[0];
      return false;
    },
    documentoAnterior() {
      if (this.documentos.length > 1) return this.documentos[1];
      return false;
    },
    documentoTipo() {
      const extension = getTypeFormatByName(this.documentoActual.file.name);
      return extension;
    },
    historialCalificaciones() {
      const calificaciones = [];
      this.documentos.forEach((documento) => {
        if (typeof documento.revision.calificacion === "number") {
          calificaciones.push({
            _id: documento._id,
            calificacion: documento.revision.calificacion,
            fecha: documento.revision.fecha,
            observaciones: documento.revision.observaciones,
            nombreArchivo: documento.file.name,
          });
        }
      });

      return calificaciones;
    },
    disableAddCal() {
      if (
        !this.documentoActual.revision.calificacion &&
        this.permisoEscritura &&
        !this.vistaSeguimiento
      )
        return false;
      return true;
    },
    disableModifyCal() {
      if (
        typeof this.documentoActual.revision.calificacion == "number" &&
        this.permisoEscritura &&
        !this.vistaSeguimiento
      )
        return false;
      return true;
    },
    disableShowCal() {
      return this.historialCalificaciones.length === 0;
    },
  },
  data() {
    return {
      loading: false,
      agregarCalificacion: false,
      editarCalificacion: false,
      previsualizarDocumento: false,
      verDetalles: false,
      verArchivo: {
        mostrar: false,
        idRecurso: null,
        filename: null,
        url: null,
        tipo: null,
        permisos: {
          descarga: true
        },
      },
      sinArchivo: { mostrar: false, nombre: null },
    };
  },
  created() {},
  methods: {
    calificacionBtn() {
      if (this.documentoActual) {
        if (typeof this.documentoActual.revision.calificacion != "number") {
          return this.documentoAnterior.revision.calificacion;
        } else {
          return this.documentoActual.revision.calificacion;
        }
      }
    },
    calificacionModificadaArchivo(calificacion) {
      this.editarCalificacion = false;
      this.$emit("calificacionModificadaArchivo", calificacion);
    },
    documentoSize(documento) {
      let size = numeral(documento.file.size).format("0.0b");
      return size;
    },
    documentoFecha(documento) {
      let fecha = DateTime.fromISO(documento.fecha).toLocaleString(
        DateTime.DATE_FULL
      );
      return fecha;
    },
    documentoExt(documento) {
      let fechaFin = !this.disponible.abierta
        ? DateTime.fromISO(this.disponible.fin)
        : null;

      let fechaDocumento = DateTime.fromISO(documento.fecha);
      let entregaExt = false;

      if (fechaFin) {
        entregaExt = fechaDocumento > fechaFin;
      }

      return entregaExt;
    },
    async mostrarArchivo(idDocumento, documento) {
      try {
        const serverResponse = await validaExistenciaService({
          url: documento.path,
        });
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.existe) {
          let url = `${this.documentsURL}/${documento.path}`;
          this.verArchivo.mostrar = true;
          this.verArchivo.idRecurso = idDocumento;
          this.verArchivo.filename = documento.name;
          this.verArchivo.url = url;
          this.verArchivo.tipo = documento.type;
        } else this.sinArchivo = { mostrar: true, nombre: documento.name };
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style scoped>
.document-size {
  margin-left: 5px;
  font-size: 12px;
  font-style: italic;
  color: gray;
}
</style>
