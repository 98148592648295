<template>
   <v-list dense class="text-left">
    <v-list-item :disabled="disableAdd" @click="$emit('addScore')">
      <v-list-item-title>Agregar calificación</v-list-item-title>
      <v-list-item-icon><v-icon small :disabled="disableAdd">mdi-book-plus-outline</v-icon></v-list-item-icon>
    </v-list-item>
    <v-list-item :disabled="disableModify" @click="$emit('modifyScore')">
      <v-list-item-title>Modificar calificación</v-list-item-title>
      <v-list-item-icon><v-icon small :disabled="disableModify">mdi-book-edit-outline</v-icon></v-list-item-icon>
    </v-list-item>
    <v-list-item :disabled="disableShow" @click="$emit('showScore')">
      <v-list-item-title>Ver calificaciones</v-list-item-title>
      <v-list-item-icon><v-icon small :disabled="disableShow">mdi-bookmark-box-multiple-outline</v-icon></v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'calificacionMenu',
  props: {
    disableAdd: { type: Boolean, default: false },
    disableModify: { type: Boolean, default: false },
    disableShow: { type: Boolean, default: false },
  },
}
</script>