<template>
  <v-container>
    <v-card outlined rounded="lg">
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer> {{ listaCotejo ? listaCotejo.nombre : "" }}
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card v-if="loadingLista" flat>
        <v-skeleton-loader type="list-item-avatar-one-line@5"></v-skeleton-loader>
      </v-card>

      <v-card-text>
        <v-list-item v-if="!esCalificacion">
          <div
            v-html="descripcion || '<i>No se ha capturado descripción.</i>'"
          ></div>
        </v-list-item>

        <v-divider class="mx-3"></v-divider>
        <p class="text-center">
          <instrumentoStatusComponent :status="status"/>
        </p>

        <!-- Secciones -->
         <v-skeleton-loader
          v-if="loadingSecciones"
          type="list-item-two-line@3"
          style="margin: 10px"
        ></v-skeleton-loader>

        <template v-else>
          <v-card
            shaped
            outlined
            style="margin: 10px"
            v-if="secciones.length === 0"
          >
            <v-toolbar dense flat class="text-bold">
              <span class="text--secondary"
                >No se han agregado secciones a esta lista cotejo.</span
              >
            </v-toolbar>
          </v-card>

          <!-- EXISTEN SECCIONES -->
          <v-container v-for="seccion in secciones" :key="seccion._id">
            <v-card shaped outlined class="card">
              <v-list-item dense flat class="seccion">
                <v-list-item-content>
                  <v-list-item-title>{{ seccion.nombre }}</v-list-item-title>
                  <v-list-item-subtitle v-if="seccion.ponderacion >= 0">{{ seccion.ponderacion }}% -  {{ seccion.puntos }} pts</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>Promedio - {{ seccion.puntos }} pts</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-simple-table class="indicador" v-if="seccion.indicadores.length != 0">
                <thead>
                  <tr>
                    <th v-if="esCalificacion" width="80"></th>
                    <th v-else width="50"></th>
                    <th class="text-left">Indicador</th>
                    <th width="110" class="text-center">Ponderación</th>
                    <th width="70" class="text-center">PTS</th>
                  </tr>
                </thead>
              </v-simple-table>

              <!-- PARTE DE INDICADORES -->
              <v-container v-for="indicador in seccion.indicadores" :key="indicador._id">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td width="12" style="padding-top: 10px">
                          <v-checkbox
                            v-if="esCalificacion"
                            v-model="selected"
                            :value="indicador._id"
                            dense
                            @change="calcularCalificacion()"
                          ></v-checkbox>
                        </td>
                        <td>{{ indicador.descripcion }}</td>
                        <td
                          v-if="indicador.ponderacion >= 0"
                          width="125"
                          class="text-center"
                        >
                          {{ indicador.ponderacion }}%
                        </td>
                        <td v-else width="125" class="text-center">Promedio</td>
                        <td width="50" class="text-right">{{ indicador.puntos }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-container>
              <!-- INDICADORES -->

              <v-divider v-if="seccion.indicadores.length != 0"></v-divider>
              <v-toolbar dense flat>
                <span v-if="seccion.indicadores.length == 0" class="text-caption">
                  No se han capturado indicadores
                </span>
              </v-toolbar>
            </v-card>
          </v-container>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import instrumentoStatusComponent from "../instrumentoStatus.vue";

import { getListaCotejoActividadService, getSeccionesListaCotejoActividadService } from "./actividadInstrumento.service.js";

export default {
  name: "listaCotejoActividad",
  props: {
    esCalificacion: { type: Boolean, default: false },
    instrumentoActividad: { type: String, default: "" },
    selectedCalificacion: { type: Array, default: () => [] },
  },
  components: {
    instrumentoStatusComponent
  },
  computed: {
    ...mapGetters(["sessionToken"]),
  },
  watch: {
    sessionToken(value) {
      if (value) { 
        this.getInstrumento(); 
        this.getSecciones(); 
      }
    },
    selectedCalificacion(value) {
      this.selected = value;
    },
  },
  mounted() {
      this.getInstrumento();
      this.getSecciones();
      this.selected = this.selectedCalificacion;
  },
  data() {
    return {
      loadingLista: false,
      loadingSecciones: false,
      listaCotejo: null,
      status: "incompleto",
      descripcion: "",
      secciones: [],
      selected: [],
      calificacion: 0
    };
  },
  methods: {
    async getInstrumento() {
      try {
        this.loadingLista = true;
        const serverResponse = await getListaCotejoActividadService(this.instrumentoActividad);
        this.loadingLista = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$router.push("/instrumentos#listas");
        } else {
          this.listaCotejo = serverResponse.listaCotejo;
          this.status = serverResponse.listaCotejo.status;
          this.descripcion = serverResponse.listaCotejo.descripcion;
        }
      } catch (error) {
        this.loadingLista = false;
        this.$appErrorMessage();
      }
    },
    async getSecciones() {
      try {
        this.loadingSecciones = true;
        const serverResponse = await getSeccionesListaCotejoActividadService(this.instrumentoActividad);
        this.loadingSecciones = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.secciones = serverResponse.secciones;
        }
      } catch (error) {
        this.loadingSecciones = false;
        this.$appErrorMessage();
      }
    },
    calcularCalificacion(){
      this.calificacion = 0;
      this.selected.forEach(element => {
        this.secciones.forEach(seccion => {
          seccion.indicadores.forEach(indicador => {
            if( element == indicador._id )
              this.calificacion += indicador.puntos;
          });
        });
      });
      if( this.calificacion > 100 || this.calificacion > 99 )
        this.calificacion = 100;
      else
        this.calificacion = Math.round(this.calificacion);
      let data = { calificacion: this.calificacion, indicadores: this.selected};
      this.$emit('calificacionActualizada', data);
    }
  }

}
</script>

<style scoped>
.card {
  transition: all 0.1s ease;
}

.card:hover {
  box-shadow: rgba(14, 84, 196, 0.2) 0px 2px 7px 0px;
}

.seccion {
  font-weight: bold;
  background-color: #f0f8ff;
}

.indicador {
  background-color: #effbff;
}
</style>
