<template>
  <div>
    <v-list-item
      dense
      @mouseover="permisoEscritura && !vistaSeguimiento ? (modificarIcon = true) : null"
      @mouseout="permisoEscritura ? (modificarIcon = false) : null"
    >
      <v-list-item-icon>
        <v-btn
          v-if="modificarIcon && !vistaSeguimiento"
          fab
          x-small
          color="primary"
          @click="modificarCriterio = true"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else color="primary">mdi-format-list-bulleted-square </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Criterio de evaluación</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="!criterioEvaluacion">No asignado</span>
          <span v-else>{{ criterioEvaluacion.nombre }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <modificarCriterioActividad
      v-if="modificarCriterio"
      :mostrar="modificarCriterio"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipoActividad="tipoActividad"
      :idCriterio="criterioEvaluacion ? criterioEvaluacion._id : null"
      @cancelar="modificarCriterio = false"
      @criterioModificado="criterioModificado"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modificarCriterioActividad from "./modificarCriterioActividad.vue";
export default {
  name: "criterioActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    tipoActividad: {
      type: String,
      enum: ["actividad", "examenAplicacion", "encuesta"],
    },
    idActividad: String,
    criterioEvaluacion: [Object, null],
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    modificarCriterioActividad,
  },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
  },
  data() {
    return {
      modificarIcon: false,
      modificarCriterio: false,
    };
  },
  methods: {
    criterioModificado(data) {
      this.modificarCriterio = false;
      this.$emit("criterioModificado", data);
    },
  },
};
</script>
