<template>
  <div>
    <datatable
      :headers="headers"
      :pagination="pagination"
      :datos="equiposLocal"
      :itemsPerPage="equiposLocal.length"
      :totalItems="equiposLocal.length"
      noDatosText="No se han generado equipos"
      hideSearch
      hidePagination
    >
      <template #table-title >
        <v-btn
          v-if="permisoEscritura && !vistaSeguimiento"
          x-small
          color="primary"
          dark
          class="agregar-equipo-btn"
          @click="editarEquipo.mostrar = true"
          >Agregar equipo</v-btn
        >

        <v-btn
          v-if="permisoEscritura && equipos.length == 0 && !vistaSeguimiento"
          x-small
          color="primary"
          dark
          class="importar-equipo-btn"
          @click="importarEquipos = true"
          >Importar equipos</v-btn
        >
      </template>

      <!-- AJUSTAR VISTA -->
      <template #item-equipo="{ item }">
        <v-col cols="9" >
        <v-list-item class="equipo-item">
            <v-list-item-action v-if="permisoEscritura && !vistaSeguimiento" class="equipo-menu">
              <equipoMenu
                :idCurso="idCurso"
                :idActividad="idActividad"
                :idEquipo="item._id"
                @asignarAdmin="asignarAdmin"
                @modificarEquipo="modificarEquipo"
                @equipoActividadEliminado="equipoActividadEliminado"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.nombre }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ item.admin.nombreCompleto }}
                <span v-if="item.integrantes.length > 1">
                  y {{ item.integrantes.length - 1 }} más.</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        </template>
      <!-- AJUSTAR VISTA -->


      <template #item-calificacion="{ item }">
        <calDirectaItem
          v-if="tipoActividad == 1"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :instrumento="instrumento"
          :tipoInstrumento="tipoInstrumento"
          :equipos="equiposLocal"
          :idAlumno="item._id"
          :idEquipo="item._id"
          :calificaciones="item.calificaciones"
          :nombre="item.nombreCompleto"
          :permisoEscritura="permisoEscritura"
          @calificacionAgregada="calificacionAgregada"
          @calificacionModificada="calificacionModificada"
        />

      <calArchivoItem 
        v-if="tipoActividad == 2"
        :idCurso="idCurso"
        :idActividad="idActividad"
        :disponible="disponible"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :equipos="equiposLocal"
        :idAlumno="item._id"
        :idEquipo="item._id"
        :nombre="item.nombreCompleto"
        :equipo="seleccionarEquipo(item._id)"
        :documentos="seleccionarDocumentosEquipo(item._id)"
        :permisoEscritura="permisoEscritura"
        @calificacionModificadaArchivo="calificacionModificadaArchivo"
      />
      </template>
    </datatable>

    <equipoForm
      v-if="editarEquipo.mostrar"
      :mostrar="editarEquipo.mostrar"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipoActividad="tipoActividad"
      :alumnos="alumnos"
      :equipos="equipos"
      :idEquipo="editarEquipo.equipo"
      :entregaActividad="entregaActividad"
      @cancelar="
        editarEquipo.equipo = null;
        editarEquipo.mostrar = false;
      "
      @equipoAgregado="equipoAgregado"
      @equipoModificado="equipoModificado"
    />

    <equipoImport
      v-if="importarEquipos"
      :mostrar="importarEquipos"
      :idModulo="idModulo"
      :idActividad="idActividad"
      :entregaActividad="entregaActividad"
      @cancelar="importarEquipos = false"
      @equiposImportados="equiposImportados"
    />

    <equipoAdmin 
      v-if="editarAdmin.mostrar"
      :mostrar="editarAdmin.mostrar"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :equipo="editarAdmin.equipo"
      @cancelar="
        editarAdmin.mostrar = false;
        editarAdmin.equipo = null;
      "
      @adminModificado="adminModificado"

    />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import datatable from "../../../widgets/datatable.vue";
import calDirectaItem from "./calDirectaItem.vue";
import calArchivoItem from "./calArchivoItem.vue";
import equipoForm from "./equipoForm.vue";
import equipoMenu from "./equipoMenu.vue";
import equipoImport from "./equipoImport.vue";
import equipoAdmin from "./equipoAdmin.vue";
import { ordenarObjetosDesc } from "../../../../helpers/ordenador";

export default {
  name: "tablaEquipos",
  props: {
    idCurso: { type: String || null, default: null },
    idActividad: { type: String || null, default: null },
    idModulo: { type: String || null, default: null },
    disponible: { type: Object, default: () => {} },
    tipoActividad: { type: Number, default: 1 },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    entregaActividad: { type: Number, default: 2 },
    alumnos: { type: Array, default: () => [] },
    equipos: { type: Array, default: () => [] },
    calificaciones: { type: Array, default: () => [] },
    documentos: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    datatable,
    calDirectaItem,
    calArchivoItem,
    equipoForm,
    equipoMenu,
    equipoImport,
    equipoAdmin
  },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    equiposLocal() {
      const equiposResponse = [];
      let numero = 1;
      for (const equipo of this.equipos) {
        let equipoItem = { numero: numero++, ...equipo };
        let calificaciones = this.calificaciones.filter(
          (x) => x.equipo == equipoItem._id
        );
        equipoItem.calificaciones = ordenarObjetosDesc(calificaciones, "fecha");
        const equipoAdmin = equipo.integrantes.find((x) => x.admin);
        equipoItem.admin = this.alumnos.find(
          (alumno) => alumno._id == equipoAdmin.usuario
        );
        equipoItem.integrantes.forEach((integrante) => {
          let { nombreCompleto, avatar } = this.alumnos.find((alumno) => alumno._id == integrante.usuario)
          integrante.nombreCompleto = nombreCompleto;
          integrante.avatar = avatar;
        });
        equipoItem.documentos = this.documentos.filter(
          (x) => x.equipo == equipoItem._id
        );
        equiposResponse.push(equipoItem);
      }
      return equiposResponse;
    },
  },
  watch: {
    equipos(value) {
      this.pagination.itemsPerPage = value.length;
    }
  },
  data() {
    return {
      loading: false,
      editarAdmin: {
        mostrar: false,
        equipo: null,
      },
      editarEquipo: {
        mostrar: false,
        equipo: null,
      },
      importarEquipos: false,
      headers: [
        {
          text: "",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "30px",
        },
        {
          text: "",
          value: "equipo",
          sortable: false,
          filterable: false,
        },
        {
          text: "",
          value: "calificacion",
          sortable: false,
          filterable: false,
          // width: "200px"
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: this.equipos.length,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },
    };
  },
  methods: {
    calificacionAgregada(calificacion) {
      this.$emit("calificacionAgregada", calificacion);
    },
    calificacionModificada(calificacion) {
      this.$emit("calificacionModificada", calificacion);
    },
    calificacionModificadaArchivo(calificacion) {
      this.$emit("calificacionModificadaArchivo", calificacion);
    },
    equipoAgregado(equipo) {
      this.editarEquipo.equipo = null;
      this.editarEquipo.mostrar = false;
      this.$emit("equipoAgregado", equipo);
    },
    equipoModificado(equipo) {
      this.editarEquipo.equipo = null;
      this.editarEquipo.mostrar = false;
      this.$emit("equipoModificado", equipo);
    },
    adminModificado(equipo) {
      this.editarAdmin.equipo = null;
      this.editarAdmin.mostrar = false;
      this.$emit("equipoModificado", equipo);
    },
    equiposImportados(equipos) {
      this.importarEquipos = false;
      this.$emit("equiposImportados", equipos);
    },
    asignarAdmin(idEquipo) {
      let { _id, nombre, integrantes} = this.equiposLocal.find((equipo) => equipo._id === idEquipo);
      this.editarAdmin.equipo = { _id, nombre, integrantes };
      this.editarAdmin.mostrar = true;
    },
    modificarEquipo(idEquipo) {
      this.editarEquipo.equipo = idEquipo;
      this.editarEquipo.mostrar = true;
    },
    equipoActividadEliminado(idEquipo) {
      this.$emit("equipoActividadEliminado", idEquipo);
    },
    seleccionarEquipo(idEquipo) {
      return this.equiposLocal.find((equipo) => { return equipo._id === idEquipo });
    },
    seleccionarDocumentosEquipo(idEquipo) {
      let equipo = this.equiposLocal.find((equipo) => { return equipo._id === idEquipo });
      return equipo.documentos;
    }
  },
};
</script>

<style scoped>
.agregar-equipo-btn {
  margin-left: 10px;
  margin-top: 10px;
  position: absolute;
}
.importar-equipo-btn {
  position: absolute;
  margin-top: 10px;
  margin-left: 130px;
}

.equipo-item {
  padding: 0px !important;
}
.equipo-menu {
  margin-right: 10px !important;
}
</style>
