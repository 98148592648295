import { getServerRequest, putServerRequest, patchServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function listadoEntregasActividadService(idCurso, idActividad) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/listadoEntregas/${idCurso}/${idActividad}`;
    return await getServerRequest(url, config);
}

export async function agregarCalificacionDirectaService(idCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/calificacionDirecta/${idCurso}/${idActividad}`;
    return await putServerRequest(url, data, config);
}

export async function modificarCalificacionDirectaService(idCurso, idActividad, idCalificacion, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/calificacionDirecta/${idCurso}/${idActividad}/${idCalificacion}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarCalificacionArchivoService(idCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/calificacionArchivo/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function agregarCalificacionGeneralService(idCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/calificacionGeneral/${idCurso}/${idActividad}`;
    return await putServerRequest(url, data, config);
}