<template>
  <v-file-input
    v-model="selectedFile"
    outlined
    dense
    :loading="loading"
    :disabled="loading || uploading"
    :hint="hint"
    :persistent-hint="loading || uploading"
    label="Subir documento"
    prepend-icon="mdi-paperclip"
    :error-messages="errors"
  >
  </v-file-input>
</template>

<script>
import { getTypeFormatByName } from '../../../../helpers/utilerias';
import { uploadDocumentService } from "./calificacionesAlumno.service";
export default {
  name: "subirDocumentoAlumno",
  props: {
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    maxFileSize: { type: Number },
    formatoArchivo: { type: Array, default: null },
  },
  watch: {
    selectedFile(file) {
      this.errors = [];

      if (file) {
        if (this.formatoArchivo) {
          const extension = getTypeFormatByName(this.selectedFile.name);
          this.formatoArchivo.includes(extension) 
            ? this.uploadDocument() 
            : this.errors.push('El documento no cumple con el formato de archivo solicitado.');

        } else this.uploadDocument();
      }

    },
  },
  data() {
    return {
      loading: false,
      uploading: false,
      uploadingProgress: 0,
      selectedFile: null,
      hint: "",
      errors: [],
    };
  },
  methods: {
    async uploadDocument() {
      try {
        const maxFileSizeBytes = this.maxFileSize * 1024 * 1024;
        if (this.selectedFile.size > maxFileSizeBytes) {
          this.errors.push(
            `El archivo no debe exceder los ${this.maxFileSize}MB`
          );
          return;
        }
        this.hint = "Subiendo... 0%";
        this.uploading = true;

        const form = new FormData();
        form.append("file", this.selectedFile);

        const uploadHandler = {
          onUploadProgress: (progressEvent) => {
            this.uploadingProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.hint = `Subiendo... ${this.uploadingProgress}%`;
            if (this.uploadingProgress >= 98) {
              this.uploading = false;
              this.loading = true;
              this.hint = `Procesando...`;
            }
          },
        };

        const serverResponse = await uploadDocumentService({
          form,
          uploadHandler,
          idCurso: this.idCurso,
          idActividad: this.idActividad,
        });
        this.uploading = false;
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else this.$emit("documentoAgregado", serverResponse.actividadDocumento);
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.uploading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
