<template>
  <v-container>

    <subirDocumentoAlumno
      v-if="puedeSubirDocumento && fechaDisponible && esAdmin && !cursoArchivado"
      :idActividad="actividad._id"
      :idCurso="idCurso"
      :maxFileSize="maxFileSize"
      style="max-width: 400px"
      :formatoArchivo="actividad.formatoArchivo"
      @documentoAgregado="documentoAgregado"
    />
    <v-card-text v-if="documentos.length == 0"
      ><i>No se ha entregado la actividad</i></v-card-text
    >
    <v-list v-else>
      <v-list-item
        v-for="documento in documentosLocal"
        :key="documento._id"
        three-line
      >
        <v-list-item-avatar>
          <v-avatar v-if="!documento.revision.calificacion && documento.revision.calificacion != 0" size="36">
            <v-icon>mdi-clock</v-icon>
          </v-avatar>
          <v-avatar v-else color="indigo" size="36">
            <span class="white--text text-strong">{{
              documento.revision.calificacion
            }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <a href="javascript:void(0);" class="link" @click="mostrarArchivo(documento._id, documento.file)">{{
              documento.file.name
            }}</a>
            <span class="document-size">{{ documento.size }}</span>
          </v-list-item-title>
          <v-list-item-subtitle
            >{{ documento.fecha }}
            <v-chip
              class="mx-1"
              x-small
              outlined
            >{{ documentoTipo }}</v-chip>
            <v-chip
              v-if="documento.entregaExt"
              x-small
              color="orange darken-1"
              dark
              style="margin-left: 5px"
              >Extemporáneo</v-chip
            ></v-list-item-subtitle
          >
          <v-list-item-subtitle>
            <span v-if="!documento.revision.calificacion && documento.revision.calificacion != 0"
              ><i>No se ha calificado la actividad.</i></span
            >
            <textSubtractComponent
              v-else
              :texto="documento.revision.observaciones || ' '"
            />
            <template v-if="documento.revision.reenvio && documento._id === documentoActual._id">
              <v-chip x-small color="orange darken-3" style="color: white"><v-icon left>mdi-alert</v-icon>Reenvío solicitado</v-chip>
            </template>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="!documento.revision.calificacion && documento.revision.calificacion != 0 && esAdmin">
          <v-btn icon x-small @click="eliminarDocumentoAction(documento._id)">
            <v-icon color="red darken-2">mdi-close-octagon</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <viewer
      v-if="verArchivo.mostrar"
      :mostrar="verArchivo.mostrar"
      :idRecurso="verArchivo.idRecurso"
      :nombreArchivo="verArchivo.filename"
      :url="verArchivo.url"
      :tipoArchivo="verArchivo.tipo"
      :permisos="verArchivo.permisos"
      @cerrar="verArchivo.mostrar=false"
    />

    <eliminarDocumentoAlumnoComponent
      v-if="eliminarDocumento.mostrar"
      :mostrar="eliminarDocumento.mostrar"
      :idCurso="idCurso"
      :idActividad="actividad._id"
      :idDocumento="eliminarDocumento.idDocumento"
      @cancelar="
        eliminarDocumento.idDocumento = null;
        eliminarDocumento.mostrar = false;
      "
      @documentoEliminado="documentoEliminado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import numeral from "numeral";
import subirDocumentoAlumno from "./subirDocumentoAlumno.vue";
import eliminarDocumentoAlumnoComponent from "./eliminarDocumentoAlumno.vue";
import textSubtractComponent from "../../../widgets/textSubtract.vue";
import viewer from "../../../widgets/fileViewer.vue";
import { getTypeFormatByName } from '../../../../helpers/utilerias';

export default {
  name: 'calificacionDocumentoAlumno',
  props: {
    idCurso: { type: String, default: null },
    actividad: { type: Object, default: () => ({}) },
    documentos: { type: Array, default: () => [] },
    maxFileSize: { type: Number, default: 1 },
    esAdmin: { type: Boolean, default: false },
    permisoEscritura: { type: Boolean, default: false },
    cursoArchivado: { type: Boolean, default: false },
  },
  components: {
    subirDocumentoAlumno,
    eliminarDocumentoAlumnoComponent,
    textSubtractComponent,
    viewer,
  },
  computed: {
    ...mapGetters(["userId", "documentsURL"]),
    fechaDisponible() {
      if (this.actividad.disponible.abierta) return true;
      var fechaHoy = DateTime.now();
      var fechaInicio = DateTime.fromISO(this.actividad.disponible.inicio);
      var fechaFin = DateTime.fromISO(this.actividad.disponible.fin);

      if (fechaHoy < fechaInicio) return false;
      if (fechaHoy > fechaFin) return this.actividad.entregaExt;
      return true;
    },
    puedeSubirDocumento() {
      if (this.documentos.length == 0) return true;
      const ultimoDocumento = this.documentos[0];
      if (typeof(ultimoDocumento.revision.calificacion) != 'number') return false; //No se ha calificadodocumento.revision.calificacion
      return ultimoDocumento.revision.reenvio;
    },
    documentosLocal() {
      const documentos = [];
      var fechaFin = !this.actividad.disponible.abierta
        ? DateTime.fromISO(this.actividad.disponible.fin)
        : null;
      this.documentos.forEach((documento) => {
        let nuevoDocumento = { ...documento };

        nuevoDocumento.fecha = DateTime.fromISO(documento.fecha).toLocaleString(
          DateTime.DATE_FULL
        );
        nuevoDocumento.size = numeral(documento.file.size).format("0.0b");

        var fechaDocumento = DateTime.fromISO(documento.fecha);
        if (fechaFin) nuevoDocumento.entregaExt = fechaDocumento > fechaFin;

        documentos.push(nuevoDocumento);
      });
      return documentos;
    },
    documentoActual() {
      if(this.documentosLocal.length > 0) return this.documentosLocal[0];
      return false;
    },
    documentoTipo() {
      const extension = getTypeFormatByName(this.documentoActual.file.name);
      return extension;
    },
  },
  data() {
    return {
      loading: true,
      eliminarDocumento: {
        mostrar: false,
        idDocumento: null,
      },
      verArchivo:{
        mostrar:false,
        idRecurso: null,
        filename: null,
        url: null,
        tipo: null,
        permisos: {
          descarga: true
        },
			}
    };
  },
  methods: {
    documentoAgregado(documento) {
      this.$emit("documentoAgregado", documento);
    },
    eliminarDocumentoAction(idDocumento) {
      this.eliminarDocumento.idDocumento = idDocumento;
      this.eliminarDocumento.mostrar = true;
    },
    documentoEliminado(idDocumento) {
      this.eliminarDocumento.idDocumento = null;
      this.eliminarDocumento.mostrar = false;
      this.$emit("documentoEliminado", idDocumento);
    },
    mostrarArchivo(idDocumento, documento){
      let url = `${this.documentsURL}/${documento.path}`;
      this.verArchivo.mostrar = true;
      this.verArchivo.idRecurso = idDocumento;
      this.verArchivo.filename = documento.name;
      this.verArchivo.url = url;
      this.verArchivo.tipo = documento.type;
    },
  },
};
</script>

<style scoped>
.document-size {
  margin-left: 5px;
  font-size: 12px;
  font-style: italic;
  color: gray;
}
</style>
