import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[(!_vm.documentoActual)?_c(VBtn,{attrs:{"disabled":"","outlined":""}},[_c(VIcon,[_vm._v("mdi-clock")])],1):_c(VMenu,{attrs:{"offset-x":"","left":"","close-delay":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
              typeof _vm.documentoActual.revision.calificacion == 'number' ||
              _vm.documentoAnterior
            )?_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":_vm.documentoAnterior &&
              typeof _vm.documentoActual.revision.calificacion != 'number'
                ? 'orange darken-1'
                : 'primary'}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.calificacionBtn())+" ")]):_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"orange darken-1"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-alert-box")])],1)]}}])},[_c('calificacionMenuComponent',{attrs:{"disableAdd":_vm.disableAddCal,"disableModify":_vm.disableModifyCal,"disableShow":_vm.disableShowCal},on:{"addScore":function($event){_vm.agregarCalificacion = true},"modifyScore":function($event){(_vm.editarCalificacion = true), (_vm.agregarCalificacion = true)},"showScore":function($event){_vm.verDetalles = true}}})],1)],1)],1),(_vm.verDetalles)?_c('detallesCalificacionComponent',{attrs:{"mostrar":_vm.verDetalles,"calificaciones":_vm.historialCalificaciones},on:{"cerrar":function($event){_vm.verDetalles = false}}}):_vm._e(),(
      (_vm.agregarCalificacion && !_vm.instrumento) ||
      (_vm.previsualizarDocumento && !_vm.instrumento)
    )?_c('agregarCalificacionArchivoComponent',{attrs:{"mostrar":_vm.agregarCalificacion || _vm.previsualizarDocumento,"mostrarCalificar":_vm.agregarCalificacion,"mostrarDocumento":_vm.previsualizarDocumento,"editarCalificacion":_vm.editarCalificacion,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"disponible":_vm.disponible,"instrumento":_vm.instrumento,"alumnos":this.alumnos,"equipos":this.equipos,"idAlumno":_vm.idAlumno,"idEquipo":_vm.idEquipo},on:{"cancelar":function($event){(_vm.agregarCalificacion = false),
        (_vm.editarCalificacion = false),
        (_vm.previsualizarDocumento = false)},"calificacionModificadaArchivo":_vm.calificacionModificadaArchivo}}):_vm._e(),(
      (_vm.agregarCalificacion && _vm.tipoInstrumento == 'listaCotejo') ||
      (_vm.previsualizarDocumento && _vm.tipoInstrumento == 'listaCotejo')
    )?_c('agregarCalificacionArchivoListaCotejoComponent',{attrs:{"mostrar":_vm.agregarCalificacion || _vm.previsualizarDocumento,"mostrarCalificar":_vm.agregarCalificacion,"mostrarDocumento":_vm.previsualizarDocumento,"editarCalificacion":_vm.editarCalificacion,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"disponible":_vm.disponible,"instrumento":_vm.instrumento,"alumnos":this.alumnos,"equipos":this.equipos,"idAlumno":_vm.idAlumno,"idEquipo":_vm.idEquipo},on:{"cancelar":function($event){(_vm.agregarCalificacion = false),
        (_vm.editarCalificacion = false),
        (_vm.previsualizarDocumento = false)},"calificacionModificadaArchivo":_vm.calificacionModificadaArchivo}}):_vm._e(),(
      (_vm.agregarCalificacion && _vm.tipoInstrumento == 'rubrica') ||
      (_vm.previsualizarDocumento && _vm.tipoInstrumento == 'rubrica')
    )?_c('agregarCalificacionArchivoRubricaComponent',{attrs:{"mostrar":_vm.agregarCalificacion || _vm.previsualizarDocumento,"mostrarCalificar":_vm.agregarCalificacion,"mostrarDocumento":_vm.previsualizarDocumento,"editarCalificacion":_vm.editarCalificacion,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"disponible":_vm.disponible,"instrumento":_vm.instrumento,"alumnos":this.alumnos,"equipos":this.equipos,"idAlumno":_vm.idAlumno,"idEquipo":_vm.idEquipo},on:{"cancelar":function($event){(_vm.agregarCalificacion = false),
        (_vm.editarCalificacion = false),
        (_vm.previsualizarDocumento = false)},"calificacionModificadaArchivo":_vm.calificacionModificadaArchivo}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }