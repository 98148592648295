<template>
    <v-dialog v-model="mostrar" fullscreen>
        <v-card
          class="overflow-y-auto"
          max-height="200"
        >
          <v-banner sticky single-line color="primary" class="text-bold" tile>
              <v-btn icon color="#FFF" @click="$emit('cancelar')"
            ><v-icon>mdi-window-close</v-icon></v-btn
          >
          </v-banner>
          <v-card-text>
            
            <listaCotejoViewComponent 
                v-if="tipoInstrumento === 'listaCotejo' "
                :esActividad="esActividad"
                :instrumentoActividad="instrumento._id"
            />

            <rubricaViewComponent 
                v-else
                :esActividad="esActividad"
                :instrumentoActividad="instrumento._id"
            />

          </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import listaCotejoViewComponent from "../actividad/listaCotejoActividad.view.vue";
import rubricaViewComponent from "../actividad/rubricaActividad.view.vue";

export default {
  name: "verInstrumentoActividad",
  props: {
    mostrar: { type: Boolean, default: false },
    instrumento: { type: Object, default: null},
    tipoInstrumento: { type: String, default: "" }
  },
  data() {
    return {
        esActividad: true,
    };
  },
  components: {
      listaCotejoViewComponent,
      rubricaViewComponent,
  },
  created() {}
}
</script>
