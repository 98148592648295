import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VListItem,{attrs:{"dense":""},on:{"mouseover":function($event){_vm.permisoEscritura && !_vm.vistaSeguimiento ? (_vm.modificarIcon = true) : null},"mouseout":function($event){_vm.permisoEscritura ? (_vm.modificarIcon = false) : null}}},[_c(VListItemIcon,[(_vm.modificarIcon && !_vm.vistaSeguimiento)?_c(VBtn,{attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.modificarEntrega = true}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.entrega == 1 ? "mdi-account" : "mdi-account-multiple"))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Entrega")]),_c(VListItemSubtitle,[(_vm.entrega == 1)?_c('span',[_vm._v("Individual")]):_c('span',[_vm._v("Equipos de hasta "+_vm._s(_vm.entrega)+" integrantes")])])],1)],1),(_vm.modificarEntrega)?_c('modificarEntregaActividad',{attrs:{"mostrar":_vm.modificarEntrega,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"tipo":_vm.tipo,"entrega":_vm.entrega,"totalEquipos":_vm.equipos.length},on:{"cancelar":function($event){_vm.modificarEntrega = false},"entregaModificada":_vm.entregaModificada}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }