import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VMenu,{attrs:{"offset-x":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[(!_vm.cursoCampus)?_c(VListItem,{on:{"click":function($event){return _vm.asignarAdmin()}}},[_c(VListItemTitle,[_vm._v("Asignar admin")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-account-star")])],1)],1):_vm._e(),_c(VListItem,{on:{"click":function($event){return _vm.modificarEquipo()}}},[_c(VListItemTitle,[_vm._v("Modificar equipo")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),(!_vm.cursoCampus)?_c(VListItem,{on:{"click":function($event){_vm.eliminarEquipo = true}}},[_c(VListItemTitle,[_c('font',{attrs:{"color":"#D32F2F"}},[_vm._v(" Eliminar equipo ")])],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"red darken-2","small":""}},[_vm._v("mdi-trash-can-outline")])],1)],1):_vm._e()],1)],1),(_vm.eliminarEquipo)?_c('eliminarEquipoComponent',{attrs:{"mostrar":_vm.eliminarEquipo,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"idEquipo":_vm.idEquipo},on:{"cancelar":function($event){_vm.eliminarEquipo=false},"equipoActividadEliminado":_vm.equipoActividadEliminado}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }